import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CastStatus } from 'utils/calendarStatus'
import { useProcessButtonVariant } from './AgendaRowActionsProcessFilled.utils'

interface AgendaRowActionsProcessFilledProps {
  castStatus: CastStatus
}

export function AgendaRowActionsProcessFilled({
  castStatus
}: AgendaRowActionsProcessFilledProps) {
  const variant = useProcessButtonVariant(castStatus)

  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        backgroundColor: variant.color,
        display: 'flex',
        margin: '0 auto',
        '&:hover': {
          backgroundColor: variant.color
        }
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <FontAwesomeIcon icon={variant.icon} />
        <Typography variant="body2" fontWeight="medium" color="inherit">
          {variant.label}
        </Typography>
      </Stack>
    </Button>
  )
}
