import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const SButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  height: '40px',
  width: '40px',
  borderRadius: '100%'
})
