import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { RecurrenceFormValues } from '../../Recurrence.utils'
import { RecurrenceChip } from '../../RecurrenceChip'
import { useWeekDayLabel } from './RecurrenceTabPanelWeekly.utils'

function RecurrenceTabPanelWeeklyComponent() {
  const { t } = useTranslation(['personalEvent'])

  const form = useFormContext<RecurrenceFormValues>()
  const weekDays = form.getValues('weekDays')

  const getWeekDayLabel = useWeekDayLabel()

  const changeWeekDays = (weekDay: number) => {
    if (weekDays.includes(weekDay)) {
      form.setValue(
        'weekDays',
        weekDays.filter(wd => wd !== weekDay)
      )
    } else {
      form.setValue('weekDays', [...weekDays, weekDay])
    }
  }

  return (
    <Box mb={1}>
      <Typography variant="h6" component="h2" mb={0.5}>
        {t('personalEvent:recurrenceDetail.days')}
      </Typography>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {[...Array(7)].map((_, i) => {
          const label = getWeekDayLabel(i)
          const weekDayNumber = i
          return (
            <RecurrenceChip
              key={label}
              label={label}
              isActive={weekDays.includes(weekDayNumber)}
              isClickable={weekDays[0] !== weekDayNumber}
              onClick={() => {
                if (weekDays[0] !== weekDayNumber) {
                  changeWeekDays(weekDayNumber)
                }
              }}
            />
          )
        })}
      </Stack>
    </Box>
  )
}

export const RecurrenceTabPanelWeekly = memo(RecurrenceTabPanelWeeklyComponent)
