import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import {
  eachDayOfInterval,
  endOfMonth,
  isSameDay,
  startOfMonth
} from 'date-fns'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { useDateFns } from 'utils/date'
import { RecurrenceFormValues } from '../../Recurrence.utils'
import { useOrdinalNumber } from './RecurrenceSectionRepeat.utils'

interface RecurringSectionRepeatProps {
  startDate: Date
}

export function RecurrenceSectionRepeat({
  startDate
}: RecurringSectionRepeatProps) {
  const { t } = useTranslation(['personalEvent'])
  const getOrdinalNumber = useOrdinalNumber()
  const form = useFormContext<RecurrenceFormValues>()
  const dateFns = useDateFns()

  const startDateWeekDay = dateFns.format(startDate, 'weekday')

  const daysOfMonth = eachDayOfInterval({
    start: startOfMonth(startDate),
    end: endOfMonth(startDate)
  }).filter(day => dateFns.format(day, 'weekday') === startDateWeekDay)

  return (
    <FormControl>
      <Typography variant="h6" component="h2" mb={0.5}>
        {t('personalEvent:recurrenceDetail.repeat.title')}
      </Typography>

      <Controller
        control={form.control}
        name="repeatType"
        render={({ field: { value, name, onChange, onBlur } }) => (
          <RadioGroup
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          >
            <FormControlLabel
              value="monthDay"
              control={<Radio size="small" />}
              label={t('personalEvent:recurrenceDetail.repeat.monthDay', {
                dayNumber: getOrdinalNumber(
                  parseInt(dateFns.format(startDate, 'dayOfMonth'))
                )
              })}
            />
            <FormControlLabel
              sx={{ mt: { xs: 1, sm: 0 } }}
              value="weekDay"
              control={<Radio size="small" />}
              label={t('personalEvent:recurrenceDetail.repeat.weekDay', {
                day: `${getOrdinalNumber(
                  daysOfMonth.findIndex(day => isSameDay(day, startDate)) + 1
                )} ${dateFns.format(startDate, 'weekday')}`
              })}
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
