import { useTranslation } from 'next-i18next'

export const useAgendaHeaderItems = () => {
  const { t } = useTranslation(['calendar'])

  return [
    {
      width: '10%',
      label: t('calendar:agenda.header.day')
    },
    {
      width: '24%',
      label: t('calendar:agenda.header.event')
    },
    {
      width: '13%',
      label: t('calendar:agenda.header.persons')
    },
    {
      width: '18%',
      label: t('calendar:agenda.header.location')
    },
    {
      width: '20%',
      label: t('calendar:agenda.header.cast')
    },
    {
      width: '15%',
      label: t('calendar:agenda.header.actions')
    }
  ]
}
