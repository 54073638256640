import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import { Controller, useFormContext } from 'react-hook-form'
import { useFilters } from '../FiltersContext'

interface FiltersSettingsSectionProps {
  title: string
  name: string
  options: { label: string; value: string | number }[]
}

export function FiltersSettingsSection({
  title,
  name,
  options
}: FiltersSettingsSectionProps) {
  const form = useFormContext()

  const formValues = form.watch(name)

  const { setFilters, activeFilters } = useFilters()

  const onFieldChange = () => {
    const activeFields = []

    if (formValues) {
      for (const [key, value] of Object.entries(formValues)) {
        if (value) {
          activeFields.push(key)
        }
      }
    }
    const newFilters = { ...activeFilters, [name]: activeFields }

    if (JSON.stringify(newFilters) !== JSON.stringify(activeFilters)) {
      setFilters(newFilters)
    }
  }

  const isAllChecked = () => {
    if (formValues) {
      return Object.values(formValues).filter(Boolean).length === options.length
    }
    return false
  }

  const isSomeChecked = () => {
    if (formValues) {
      return Object.values(formValues).some(Boolean)
    }
    return false
  }

  const handleAllChange = () => {
    if (isAllChecked()) {
      options.forEach(option => {
        form.setValue(name + '.' + option.value, false)
      })
      onFieldChange()
    } else {
      options.forEach(option => {
        form.setValue(name + '.' + option.value, true)
      })
      onFieldChange()
    }
  }

  return (
    <Box>
      <FormControlLabel
        label={
          <Typography variant="body2" fontWeight="bold">
            {title}
          </Typography>
        }
        control={
          <Checkbox
            checked={isAllChecked()}
            indeterminate={!isAllChecked() && isSomeChecked()}
            disabled={!formValues}
            onChange={handleAllChange}
          />
        }
      />

      <FormGroup>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            name={name + '.' + option.value}
            control={
              <Controller
                name={name + '.' + option.value}
                control={form.control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    size="small"
                    checked={!!value}
                    onChange={event => {
                      onChange(event)
                      onFieldChange()
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            }
            label={<Typography variant="body2">{option.label}</Typography>}
          />
        ))}
      </FormGroup>
    </Box>
  )
}
