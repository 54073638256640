import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import { Filters, useFilters } from '../FiltersContext'

interface FiltersActiveItemProps {
  label: ReactNode
  icon: IconProp
  parent?: keyof Filters
  value?: string | number
  tooltip?: string
}

export function FiltersActiveItem({
  parent,
  value,
  tooltip,
  label,
  icon
}: FiltersActiveItemProps) {
  const { activeFilters, setFilters } = useFilters()

  const handleDelete = () => {
    if (!parent) return

    const newFilters = { ...activeFilters }
    const index = newFilters[parent]?.indexOf(value as never)

    if (index !== undefined && index > -1) {
      newFilters[parent]?.splice(index, 1)
    } else {
      newFilters[parent] = []
    }
    setFilters(newFilters)
  }

  return (
    <Tooltip title={tooltip} arrow>
      <Chip
        label={
          <Typography variant="caption">
            <Stack direction="row" alignItems="center" gap={0.5}>
              <FontAwesomeIcon icon={icon} /> {label}
            </Stack>
          </Typography>
        }
        variant="outlined"
        onDelete={value ? handleDelete : undefined}
      />
    </Tooltip>
  )
}
