import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DatePicker } from 'components/form/DatePicker'
import { DateTimePicker } from 'components/form/DateTimePicker'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'react-use'
import { useDateFns } from 'utils/date'
import { isDate } from 'utils/types'
import { PersonalEventFormValuesValidated } from '../PersonalEventDetailModal.utils'

const now = new Date()

export function PersonalEventDetailModalDateRange() {
  const { t } = useTranslation(['personalEvent'])

  const form = useFormContext<PersonalEventFormValuesValidated>()
  const startDate = form.watch('startDate')
  const endDate = form.watch('endDate')
  const isFullDay = form.watch('isFullDay')

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const dateFns = useDateFns()

  const [activeInput, setActiveInput] = useState<'start' | 'end' | null>(null)
  const [initDateDiff, setInitDateDiff] = useState<number | null>(null)

  const [_, cancelDebounce] = useDebounce(
    () => {
      if (
        startDate &&
        activeInput === 'start' &&
        initDateDiff &&
        isDate(startDate) &&
        dateFns.isAfter(startDate, endDate)
      ) {
        const updatedEndDate = dateFns.addSeconds(startDate, initDateDiff)
        form.setValue('endDate', updatedEndDate)
      } else if (
        endDate &&
        activeInput === 'end' &&
        initDateDiff &&
        isDate(endDate) &&
        dateFns.isBefore(endDate, startDate)
      ) {
        const updatedStartDate = dateFns.addSeconds(endDate, -initDateDiff)
        form.setValue('startDate', updatedStartDate)
      }
      setActiveInput(null)
      setInitDateDiff(null)
    },
    1000,
    [activeInput]
  )

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      mt={{ xs: 1.5, sm: 0 }}
      alignItems="center"
      justifyContent={{ xs: 'start', sm: 'center' }}
      gap={2}
    >
      <Controller
        name="startDate"
        control={form.control}
        render={({ field: { onChange, value, name } }) =>
          isFullDay ? (
            <DatePicker
              name={name}
              minDate={now}
              value={value}
              label={t('personalEvent:detail.fields.startDate')}
              isRequired
              sx={{
                width: isSmallDevice ? '100% !important' : 'auto'
              }}
              onAccept={() => {
                cancelDebounce()
                if (initDateDiff) {
                  const updatedStartDate = dateFns.addSeconds(
                    endDate,
                    initDateDiff
                  )
                  form.setValue('endDate', updatedStartDate)
                }
                setActiveInput(null)
                setInitDateDiff(null)
              }}
              onChange={v => {
                if (v && isDate(startDate) && dateFns.isAfter(v, endDate)) {
                  if (activeInput === 'end') {
                    cancelDebounce()
                  } else if (!activeInput) {
                    const diff = dateFns.getDiff(endDate, startDate, 'seconds')
                    setInitDateDiff(diff)
                    setActiveInput('start')
                  }
                  onChange(v)
                } else {
                  onChange(v as Date | ChangeEvent<Element>)
                }
              }}
            />
          ) : (
            <DateTimePicker
              name={name}
              minDateTime={now}
              value={value}
              label={t('personalEvent:detail.fields.startDate')}
              isRequired
              sx={{
                width: isSmallDevice ? '100% !important' : 'auto'
              }}
              onAccept={() => {
                cancelDebounce()
                if (initDateDiff) {
                  const updatedStartDate = dateFns.addSeconds(
                    endDate,
                    initDateDiff
                  )
                  form.setValue('endDate', updatedStartDate)
                }
                setActiveInput(null)
                setInitDateDiff(null)
              }}
              onChange={v => {
                if (v && isDate(startDate) && dateFns.isAfter(v, endDate)) {
                  if (activeInput === 'end') {
                    cancelDebounce()
                  } else if (!activeInput) {
                    const diff = dateFns.getDiff(endDate, startDate, 'seconds')
                    setInitDateDiff(diff)
                    setActiveInput('start')
                  }
                  onChange(v)
                } else {
                  onChange(v as Date | ChangeEvent<Element>)
                }
              }}
            />
          )
        }
      />

      {!isSmallDevice && <Typography>–</Typography>}

      <Controller
        name="endDate"
        control={form.control}
        render={({ field: { onChange, value, name } }) =>
          isFullDay ? (
            <DatePicker
              name={name}
              minDate={now}
              value={value}
              label={t('personalEvent:detail.fields.endDate')}
              isRequired
              sx={{
                width: isSmallDevice ? '100% !important' : 'auto'
              }}
              onAccept={() => {
                cancelDebounce()
                if (initDateDiff) {
                  const updatedStartDate = dateFns.addSeconds(
                    endDate,
                    -initDateDiff
                  )
                  form.setValue('startDate', updatedStartDate)
                }
                setActiveInput(null)
                setInitDateDiff(null)
              }}
              onChange={v => {
                if (v && isDate(startDate) && dateFns.isAfter(v, endDate)) {
                  if (activeInput === 'end') {
                    cancelDebounce()
                  } else if (!activeInput) {
                    const diff = dateFns.getDiff(endDate, startDate, 'seconds')
                    setInitDateDiff(diff)
                    setActiveInput('start')
                  }
                  onChange(v)
                } else {
                  onChange(v as Date | ChangeEvent<Element>)
                }
              }}
            />
          ) : (
            <DateTimePicker
              name={name}
              minDateTime={now}
              value={value}
              label={t('personalEvent:detail.fields.endDate')}
              isRequired
              sx={{
                width: isSmallDevice ? '100% !important' : 'auto'
              }}
              onAccept={() => {
                cancelDebounce()
                if (initDateDiff) {
                  const updatedStartDate = dateFns.addSeconds(
                    endDate,
                    -initDateDiff
                  )
                  form.setValue('startDate', updatedStartDate)
                }
                setActiveInput(null)
                setInitDateDiff(null)
              }}
              onChange={v => {
                if (v && isDate(endDate) && dateFns.isBefore(v, startDate)) {
                  if (activeInput === 'start') {
                    cancelDebounce()
                  } else if (!activeInput) {
                    const diff = dateFns.getDiff(endDate, startDate, 'seconds')
                    setInitDateDiff(diff)
                    setActiveInput('end')
                  }
                  onChange(v)
                } else {
                  onChange(v as Date | ChangeEvent<Element>)
                }
              }}
            />
          )
        }
      />
    </Stack>
  )
}
