import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface RecurrenceChipProps {
  isActive: boolean
  label: string
  isClickable?: boolean
  onClick: () => void
}

export function RecurrenceChip({
  isActive,
  label,
  isClickable,
  onClick
}: RecurrenceChipProps) {
  return (
    <Chip
      key={label}
      label={
        <Stack direction="row" alignItems="center" gap={0.5}>
          {isActive ? (
            <FontAwesomeIcon icon={faCircleCheck} />
          ) : (
            <Typography color="text.secondary" fontSize="inherit">
              <FontAwesomeIcon icon={faCircle} />
            </Typography>
          )}
          {label}
        </Stack>
      }
      clickable={isClickable}
      color={isActive ? 'primary' : 'default'}
      onClick={onClick}
    />
  )
}
