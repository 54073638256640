import DateFnsUtils from '@date-io/date-fns'
import { useQueryClient } from '@tanstack/react-query'
import {
  CalendarCastDTO,
  CalendarConfirmDTOResponseItem,
  CalendarDeclineDTOResponseItem,
  CalendarFastResponseDTO
} from 'api/generated/dotu/dotu.schemas'
import {
  getGetApiProcessCalendarFastQueryKey,
  usePostApiProcessConfirmInvitations,
  usePostApiProcessDeclineInvitations
} from 'api/generated/dotu/process'
import { addDays } from 'date-fns'
import { useDateFns } from 'utils/date'
import { localStorageClient } from 'utils/dom'
import { useNotifications } from 'utils/notifications'
import { useCalendarListQuery } from './calendarList'

const now = new Date()

const getStartDate = (
  dateFns: DateFnsUtils,
  startDate?: Date,
  jsonParams?: { end: string; start: string }
) => {
  if (startDate) {
    return addDays(startDate, -1).toISOString()
  } else if (jsonParams?.start) {
    return addDays(new Date(jsonParams.start), -1).toISOString()
  } else {
    return addDays(dateFns.startOfMonth(now), -1).toISOString()
  }
}

const getEndDate = (
  dateFns: DateFnsUtils,
  endDate?: Date,
  jsonParams?: { end: string; start: string }
) => {
  if (endDate) {
    return addDays(endDate, 1).toISOString()
  } else if (jsonParams?.end) {
    return addDays(new Date(jsonParams.end), 1).toISOString()
  } else {
    return addDays(dateFns.endOfMonth(now), 1).toISOString()
  }
}

export function useCalendarActionsMutation(
  startDate?: Date,
  endDate?: Date,
  onUpdate?: (status: number) => void
) {
  const queryClient = useQueryClient()
  const dateFns = useDateFns()

  const { refetch: refetchCalendar } = useCalendarListQuery()

  const { refetch } = useNotifications()

  const updateQuery = (
    updatedProcess:
      | CalendarConfirmDTOResponseItem
      | CalendarDeclineDTOResponseItem
  ) => {
    refetch()
    const savedParams = localStorageClient.getItem('calendarParams')
    const jsonParams = savedParams ? JSON.parse(savedParams) : null

    if (onUpdate) {
      onUpdate(updatedProcess.status ?? 0)
    }

    const previousData = queryClient.getQueryData(
      getGetApiProcessCalendarFastQueryKey({
        from: getStartDate(dateFns, startDate, jsonParams),
        to: getEndDate(dateFns, endDate, jsonParams)
      })
    ) as CalendarFastResponseDTO

    if (previousData?.processes) {
      const processIndexes = previousData.processes
        .map((e, i) =>
          e.id === updatedProcess.processId ||
          e.tourId === updatedProcess.tourId
            ? i
            : ''
        )
        .filter(String) as number[]

      const newProcesses = [...previousData.processes]

      processIndexes.forEach(processIndex => {
        if (!previousData.processes) return

        const process = previousData.processes[processIndex]

        const castIndex = process.cast?.findIndex(
          cast => cast.id === updatedProcess.castId
        ) as number

        const processCasts = process.cast as CalendarCastDTO[]
        const cast = processCasts[castIndex]

        const newCast = { ...cast }

        if (newProcesses[processIndex]?.cast?.[castIndex]) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newProcesses[processIndex].cast![castIndex] = {
            ...newCast,
            status: updatedProcess.status
          }
        }
      })

      queryClient.setQueryData(
        getGetApiProcessCalendarFastQueryKey(),
        (old?: CalendarFastResponseDTO) => {
          if (old) {
            return {
              ...old,
              processes: newProcesses
            }
          } else {
            return previousData
          }
        }
      )
    }
  }

  const { mutate: accept, isLoading: isLoadingAccept } =
    usePostApiProcessConfirmInvitations({
      mutation: {
        onSuccess: data => {
          if (data.items?.length && data.items.length === 1) {
            updateQuery(data.items[0])
          } else {
            refetchCalendar()
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
          console.error(error)

          if (error.errorCode && error.errorCode === 'cast/alreadyAccepted') {
            if (onUpdate) {
              onUpdate(4)
            }
            refetchCalendar()
          }
        }
      }
    })

  const { mutate: decline, isLoading: isLoadingDecline } =
    usePostApiProcessDeclineInvitations({
      mutation: {
        onSuccess: data => {
          if (data.items?.length && data.items.length === 1) {
            updateQuery(data.items[0])
          } else {
            refetchCalendar()
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
          console.error(error)

          if (error.errorCode && error.errorCode === 'cast/alreadyDeclined') {
            if (onUpdate) {
              onUpdate(3)
            }
            refetchCalendar()
          }
        }
      }
    })

  return {
    accept,
    decline,
    isLoadingAccept,
    isLoadingDecline
  }
}
