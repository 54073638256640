import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormContext } from 'react-hook-form'
import { getFormError } from 'utils/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Option = any

type AutoCompleteProps = Omit<
  AutocompleteProps<Option, boolean, boolean, boolean>,
  'renderInput'
> & {
  name: string
  options: Option[]
  label?: string
  isRequired?: boolean
}

export function AutoComplete({
  name,
  options,
  label,
  isRequired,
  ...restProps
}: AutoCompleteProps) {
  const form = useFormContext()

  const error = getFormError({
    form,
    name
  })

  return (
    <Autocomplete
      disablePortal
      options={options}
      {...restProps}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          label={label + (isRequired ? ' *' : '')}
          error={!!error}
          helperText={
            error && (
              <>
                <FontAwesomeIcon icon={faCircleExclamation} /> {error}
              </>
            )
          }
          fullWidth
        />
      )}
    />
  )
}
