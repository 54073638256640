import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import { useDateFns } from 'utils/date'

const now = new Date()

export const useWeekDayLabel = () => {
  const dateFns = useDateFns()

  const daysOfWeek = eachDayOfInterval({
    start: dateFns.startOfWeek(now),
    end: dateFns.endOfWeek(now)
  })

  return (weekDayNumber: number) => {
    const label = dateFns.format(daysOfWeek[weekDayNumber], 'weekday')
    return label[0].toUpperCase() + label.slice(1).toLowerCase()
  }
}
