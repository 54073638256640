import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { SessionCompanyDTO } from 'api/generated/dotu/dotu.schemas'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { AutoComplete } from 'components/form/AutoComplete'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  PersonalEventFormValues,
  useAllPersons
} from '../PersonalEventDetailModal.utils'
import { usePersonsAlertData } from './PersonalEventDetailModalPerson.utils'

interface FormPerson {
  name: string
  email: string | null
  companyIds: number[]
}

export function PersonalEventDetailModalPerson() {
  const { data, isLoading } = useGetApiStartCalendar()
  const { t } = useTranslation(['personalEvent'])

  const form = useFormContext<PersonalEventFormValues>()
  const getAllPersons = useAllPersons()

  const personsAlertData = usePersonsAlertData()

  const person = form.watch('person')

  const [personTheaters, setPersonTheaters] = useState<SessionCompanyDTO[]>([])
  const [isAlertDisplayed, setAlertDisplayed] = useState(
    personsAlertData && personsAlertData?.length > 0
  )

  useEffect(() => {
    form.resetField('theaters')
    const theaters = person
      ? data?.companies?.filter(theater =>
          person.companyIds.includes(theater.id || 0)
        )
      : []

    setPersonTheaters(theaters ?? [])
    form.setValue('theaters', theaters ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person])

  if (isLoading) {
    return null
  }

  return (
    <>
      {getAllPersons().length !== 1 && (
        <>
          <Controller
            name="person"
            control={form.control}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <AutoComplete
                name={name}
                value={value || null}
                label={t('personalEvent:detail.fields.person')}
                onChange={(_e, data) => onChange(data)}
                onBlur={onBlur}
                filterSelectedOptions
                isRequired
                isOptionEqualToValue={(option: FormPerson, value: FormPerson) =>
                  option.email === value.email
                }
                getOptionLabel={(option: FormPerson) =>
                  option.name
                    ? `${option.name} (${option.email})`
                    : option.email ?? ''
                }
                options={getAllPersons()}
              />
            )}
          />
        </>
      )}

      {isAlertDisplayed && (
        <Alert
          severity="error"
          sx={{ mb: 1, mt: -1 }}
          onClose={() => setAlertDisplayed(false)}
        >
          {t('personalEvent:detail.persons.warning.description')}

          <ul css={{ marginBottom: 0, marginTop: '6px' }}>
            {personsAlertData?.map(person => (
              <li key={person.id}>
                <Typography
                  variant="body2"
                  fontWeight="medium"
                  component="span"
                >
                  {person.name}
                </Typography>
                {person.companyName ? `– ${person.companyName}` : null} (
                {person.alert.map((alert, index) => {
                  let str = t(`personalEvent:detail.persons.warning.${alert}`)
                  if (index > 0) {
                    str = ', ' + str
                  }
                  return str
                })}
                )
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {personTheaters.length !== 1 && (
        <Controller
          name="theaters"
          control={form.control}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <AutoComplete
              name={name}
              value={value ?? []}
              disabled={!person}
              label={t('personalEvent:detail.fields.theater')}
              onChange={(_e, data) => onChange(data)}
              onBlur={onBlur}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              options={personTheaters}
              isRequired
              filterSelectedOptions
              multiple
            />
          )}
        />
      )}
    </>
  )
}
