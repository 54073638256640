import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { MAX_OCCURRENCES, RecurrenceFormValues } from '../../Recurrence.utils'
import { Interval } from './RecurrenceTabPanelIrregularly.utils'

interface RecurrenceTabPanelIrregularlyEmptyProps {
  getNewOccurrence: (index: number) => Date | null
  interval: Interval
  setInterval: (interval: Interval) => void
}

export function RecurrenceTabPanelIrregularlyEmpty({
  getNewOccurrence,
  interval,
  setInterval
}: RecurrenceTabPanelIrregularlyEmptyProps) {
  const { t } = useTranslation(['personalEvent'])

  const [times, setTimes] = useState(1)

  const form = useFormContext<RecurrenceFormValues>()

  const handleAddAll = () => {
    const allDates = []

    for (let i = 1; i <= times; i++) {
      const date = getNewOccurrence(i)
      if (date) {
        allDates.push(date)
      }
    }

    form.setValue('irregular', allDates)
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} mb={2} flexWrap="wrap">
      <Typography>
        {t('personalEvent:recurrenceDetail.irregularly.add.descriptionStart')}
      </Typography>
      <TextField
        sx={{ minWidth: '50px' }}
        size="small"
        type="number"
        value={times}
        onChange={e => {
          setTimes(parseInt(e.target.value))
        }}
        InputProps={{ inputProps: { min: 1, max: MAX_OCCURRENCES } }}
      />
      <Typography>
        {t('personalEvent:recurrenceDetail.irregularly.add.descriptionEnd')}
      </Typography>

      <Select
        value={interval}
        size="small"
        onChange={event => setInterval(event.target.value as Interval)}
      >
        <MenuItem value="day">
          {t('personalEvent:recurrenceDetail.irregularly.intervals.day')}
        </MenuItem>
        <MenuItem value="week">
          {t('personalEvent:recurrenceDetail.irregularly.intervals.week')}
        </MenuItem>
        <MenuItem value="month">
          {t('personalEvent:recurrenceDetail.irregularly.intervals.month')}
        </MenuItem>
      </Select>

      <Button
        variant="contained"
        size="small"
        sx={{ ml: 'auto' }}
        onClick={handleAddAll}
      >
        {t('personalEvent:recurrenceDetail.irregularly.add.button')}
      </Button>
    </Stack>
  )
}
