import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import { getCastStatus, getProcessStatus } from 'utils/calendarStatus'
import { Filters } from './FiltersContext'

export const getFilteredEvents = (
  events: CalendarEvent[],
  filters: Filters
) => {
  return events.filter(event => {
    let isDisplayed = true

    if (isDisplayed && filters?.eventTypes) {
      if (event.eventType === 'tour') {
        isDisplayed = filters.eventTypes.includes('tourProcess')
      } else {
        isDisplayed = filters.eventTypes.includes(event.eventType)
      }
    }
    if (isDisplayed && filters?.castStatuses && event.eventType === 'process') {
      const status = getCastStatus(event.cast.status)
      if (status === 'HIDDEN') {
        return false
      }
      isDisplayed = filters.castStatuses.includes(status)
    }
    if (
      isDisplayed &&
      filters?.processStatuses &&
      event.eventType === 'process'
    ) {
      const status = getProcessStatus(event.status)
      if (status === 'HIDDEN') {
        return false
      }
      isDisplayed = filters.processStatuses.includes(status)
    }
    if (isDisplayed && filters?.resourceIds && event.eventType !== 'tour') {
      const resourcesIds = filters?.resourceIds?.map(resourceId =>
        parseInt(resourceId.toString())
      )

      const resourcesDisplayed: boolean[] = []

      event?.resources.forEach(resource => {
        if (resource.id) {
          resourcesDisplayed.push(Boolean(resourcesIds.includes(resource.id)))
        }
      })

      if (
        resourcesDisplayed.length > 0 &&
        !resourcesDisplayed.some(resource => resource === true)
      ) {
        isDisplayed = false
      }
    }

    return isDisplayed
  })
}
