import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'

type SelectProps = Omit<MuiSelectProps, 'name'> & {
  name: string
  options: { value: string | number; label: string }[]
}

export function Select({ name, label, options, ...restProps }: SelectProps) {
  return (
    <FormControl fullWidth>
      {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}

      <MuiSelect labelId={`${name}-label`} label={label} {...restProps}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
