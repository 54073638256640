import { faUser as faUserRegular } from '@fortawesome/pro-regular-svg-icons'
import {
  faBuildingColumns,
  faBus,
  faMasksTheater,
  faTicket,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/pro-solid-svg-icons'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useGetApiSession } from 'api/generated/dotu/session'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { useTranslation } from 'next-i18next'
import { useFilters } from '../FiltersContext'
import {
  useCastStatusLabel,
  useProcessStatusLabel,
  useResourceLabel
} from './FiltersActive.utils'
import { FiltersActiveItem } from './FiltersActiveItem'

export function FiltersActiveCollapsed() {
  const { t } = useTranslation(['calendar', 'app'])

  const { activeFilters } = useFilters()
  const { data: dataStart, isLoading: isLoadingStart } =
    useGetApiStartCalendar()
  const { data: dataSession, isLoading: isLoadingSession } = useGetApiSession()

  const getResourceLabel = useResourceLabel()
  const getCastStatusLabel = useCastStatusLabel()
  const getProcessStatusLabel = useProcessStatusLabel()

  const isAllSelected =
    !activeFilters ||
    (activeFilters.eventTypes?.length === 3 &&
      activeFilters.processStatuses?.length === 3 &&
      activeFilters.castStatuses?.length === 4 &&
      activeFilters.resourceIds?.length === dataStart?.resources?.length)

  const filterResourcesManaged = activeFilters?.resourceIds
    ? activeFilters.resourceIds.filter(r =>
        dataSession?.resourcesManaged?.some(
          rm => rm.id === parseInt(r.toString())
        )
      )
    : []

  const filterResourcesMy = activeFilters?.resourceIds
    ? activeFilters.resourceIds.filter(r =>
        dataSession?.resources?.some(rm => rm.id === parseInt(r.toString()))
      )
    : []

  const getEventTypesLabel = () => {
    if (!activeFilters?.eventTypes) return ''

    if (activeFilters.eventTypes.length === 3) {
      return t('calendar:filters.values.eventTypes.all')
    }

    if (activeFilters.eventTypes.length === 2) {
      return (
        <Stack direction="row" gap={0.25}>
          {t('calendar:filters.values.eventTypes.n') + ':'}
          <Typography variant="caption" fontWeight="medium">
            {activeFilters.eventTypes.length}
          </Typography>
        </Stack>
      )
    }

    if (activeFilters?.eventTypes[0] === 'personalEvent') {
      return t('calendar:filters.values.personalEvent')
    }

    if (activeFilters?.eventTypes[0] === 'tourProcess') {
      return t('calendar:filters.values.tour')
    }

    if (activeFilters?.eventTypes[0] === 'process') {
      return t('calendar:filters.values.process')
    }

    return ''
  }

  if (isLoadingStart || isLoadingSession || isAllSelected) return null

  return (
    <>
      {!!activeFilters?.eventTypes?.length && (
        <FiltersActiveItem
          parent="eventTypes"
          label={getEventTypesLabel()}
          icon={
            activeFilters.eventTypes.length === 1 &&
            activeFilters.eventTypes[0] === 'personalEvent'
              ? faUmbrellaBeach
              : activeFilters.eventTypes[0] === 'tourProcess'
              ? faBus
              : faTicket
          }
        />
      )}

      {!!activeFilters?.processStatuses?.length && (
        <FiltersActiveItem
          tooltip={
            activeFilters.processStatuses.length < 4 &&
            activeFilters.processStatuses.length > 1
              ? activeFilters.processStatuses
                  .map(
                    (status, index) =>
                      getProcessStatusLabel(status) +
                      (activeFilters?.processStatuses?.length &&
                      index !== activeFilters.processStatuses.length - 1
                        ? ', '
                        : '')
                  )
                  .join('')
              : undefined
          }
          label={
            activeFilters.processStatuses.length === 3 ? (
              t('calendar:filters.values.processStatuses.all')
            ) : activeFilters.processStatuses.length === 1 ? (
              getCastStatusLabel(activeFilters.processStatuses[0])
            ) : (
              <Stack direction="row" gap={0.25}>
                {t('calendar:filters.values.processStatuses.n') + ':'}
                <Typography variant="caption" fontWeight="medium">
                  {activeFilters.processStatuses.length}
                </Typography>
              </Stack>
            )
          }
          icon={faBuildingColumns}
        />
      )}

      {!!activeFilters?.castStatuses?.length && (
        <FiltersActiveItem
          tooltip={
            activeFilters.castStatuses.length < 4 &&
            activeFilters.castStatuses.length > 1
              ? activeFilters.castStatuses
                  .map(
                    (status, index) =>
                      getCastStatusLabel(status) +
                      (activeFilters?.castStatuses?.length &&
                      index !== activeFilters.castStatuses.length - 1
                        ? ', '
                        : '')
                  )
                  .join('')
              : undefined
          }
          label={
            activeFilters.castStatuses.length === 4 ? (
              t('calendar:filters.values.castStatuses.all')
            ) : activeFilters.castStatuses.length === 1 ? (
              getCastStatusLabel(activeFilters.castStatuses[0])
            ) : (
              <Stack direction="row" gap={0.25}>
                {t('calendar:filters.values.castStatuses.n') + ':'}
                <Typography variant="caption" fontWeight="medium">
                  {activeFilters.castStatuses.length}
                </Typography>
              </Stack>
            )
          }
          icon={faMasksTheater}
        />
      )}

      {filterResourcesMy.length > 0 && (
        <FiltersActiveItem
          tooltip={
            dataSession?.resources &&
            filterResourcesMy.length < dataSession.resources.length &&
            filterResourcesMy.length > 1
              ? filterResourcesMy
                  .map(
                    (id, index) =>
                      getResourceLabel(id) +
                      (filterResourcesMy.length &&
                      index !== filterResourcesMy.length - 1
                        ? ', '
                        : '')
                  )
                  .join('')
              : undefined
          }
          label={
            filterResourcesMy.length === dataSession?.resources?.length ? (
              t('calendar:filters.values.myAccounts.all')
            ) : filterResourcesMy.length === 1 ? (
              getResourceLabel(filterResourcesMy[0])
            ) : (
              <Stack direction="row" gap={0.25}>
                {t('calendar:filters.values.myAccounts.n') + ':'}
                <Typography variant="caption" fontWeight="medium">
                  {filterResourcesMy.length}
                </Typography>
              </Stack>
            )
          }
          icon={faUser}
        />
      )}

      {filterResourcesManaged.length > 0 && (
        <FiltersActiveItem
          tooltip={
            dataSession?.resourcesManaged &&
            filterResourcesManaged.length <
              dataSession.resourcesManaged.length &&
            filterResourcesManaged.length > 1
              ? filterResourcesManaged
                  .map(
                    (id, index) =>
                      getResourceLabel(id) +
                      (filterResourcesManaged.length &&
                      index !== filterResourcesManaged.length - 1
                        ? ', '
                        : '')
                  )
                  .join('')
              : undefined
          }
          label={
            filterResourcesManaged.length ===
            dataSession?.resourcesManaged?.length ? (
              t('calendar:filters.values.managedAccounts.all')
            ) : filterResourcesManaged.length === 1 ? (
              getResourceLabel(filterResourcesManaged[0])
            ) : (
              <Stack direction="row" gap={0.25}>
                {t('calendar:filters.values.managedAccounts.n') + ':'}
                <Typography variant="caption" fontWeight="medium">
                  {filterResourcesManaged.length}
                </Typography>
              </Stack>
            )
          }
          icon={faUserRegular}
        />
      )}
    </>
  )
}
