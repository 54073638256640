import getWeekOfMonth from 'date-fns/getWeekOfMonth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Frequency, Options, RRule } from 'rrule'
import { useDefaultZodErrorMap } from 'utils/validations'
import zod, { date, number, object, infer as zodInfer } from 'zod'

export const MAX_OCCURRENCES = 12

export function translateWeekDay(n: number) {
  const mapping = [6, 0, 1, 2, 3, 4, 5]
  return mapping[n % 7]
}

export const a11yProps = (index: number) => {
  return {
    id: `recurrence-tab-${index}`,
    'aria-controls': `recurrence-tabpanel-${index}`
  }
}

export function useRecurrenceFormValidationSchema() {
  const schema = object({
    repeatType: zod.enum(['monthDay', 'weekDay']),
    count: number(),
    weekDays: number().array(),
    months: number().array(),
    irregular: date().array()
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export type RecurrenceFormValues = zodInfer<
  ReturnType<typeof useRecurrenceFormValidationSchema>['schema']
>

export const useRecurrenceDates = (
  startDate: Date,
  form: UseFormReturn<RecurrenceFormValues, unknown>
) => {
  const [dates, setDates] = useState<Date[]>([])

  const values = form.watch()

  const [isIrregular, setIsIrregular] = useState(false)

  const [recurrenceConfig, setRecurrenceConfig] = useState<Partial<Options>>({
    dtstart: startDate,
    count: 1,
    freq: Frequency.WEEKLY
  })

  const changeStartDate = useCallback(
    (newDate: Date) =>
      setRecurrenceConfig({ ...recurrenceConfig, dtstart: newDate }),
    [recurrenceConfig]
  )

  useEffect(() => {
    if (isIrregular && values.irregular) {
      setDates([startDate, ...values.irregular])
    }
  }, [isIrregular, startDate, values])

  const changeFrequency = useCallback(
    (
      frequency:
        | Frequency.WEEKLY
        | Frequency.MONTHLY
        | Frequency.YEARLY
        | 'IRREGULARLY'
    ) => {
      if (frequency === 'IRREGULARLY') {
        setIsIrregular(true)
      } else if (frequency === Frequency.WEEKLY) {
        setIsIrregular(false)
        setRecurrenceConfig({
          ...recurrenceConfig,
          freq: Frequency.WEEKLY,
          bymonth: undefined,
          bysetpos: undefined,
          bymonthday: undefined,
          byweekday: values.weekDays.map(day => day - 1)
        })
      } else if (frequency === Frequency.MONTHLY) {
        setIsIrregular(false)
        setRecurrenceConfig({
          ...recurrenceConfig,
          freq: Frequency.MONTHLY,
          bymonth: undefined,
          bysetpos:
            values.repeatType === 'weekDay'
              ? getWeekOfMonth(startDate)
              : undefined,
          bymonthday:
            values.repeatType === 'monthDay' ? startDate.getDate() : undefined,
          byweekday:
            values.repeatType === 'weekDay' ? startDate.getDay() - 1 : undefined
        })
      } else if (frequency === Frequency.YEARLY) {
        setIsIrregular(false)
        setRecurrenceConfig({
          ...recurrenceConfig,
          freq: Frequency.YEARLY,
          bysetpos:
            values.repeatType === 'weekDay'
              ? getWeekOfMonth(startDate)
              : undefined,
          bymonthday:
            values.repeatType === 'monthDay' ? startDate.getDate() : undefined,
          byweekday:
            values.repeatType === 'weekDay' ? startDate.getDay() - 1 : undefined
        })
      }
    },
    [recurrenceConfig, startDate, values.repeatType, values.weekDays]
  )

  const getNewDates = useMemo(() => {
    const rule = new RRule(recurrenceConfig)
    return rule.all()
  }, [recurrenceConfig])

  useEffect(() => {
    setDates(getNewDates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrenceConfig])

  useEffect(() => {
    let newConfig = { ...recurrenceConfig }

    if (recurrenceConfig.count !== values.count) {
      newConfig = {
        ...newConfig,
        count: values.count
      }
    }
    if (
      recurrenceConfig.freq === Frequency.WEEKLY &&
      recurrenceConfig.byweekday !== values.weekDays
    ) {
      newConfig = {
        ...newConfig,
        byweekday: values.weekDays
      }
    }
    if (
      recurrenceConfig.freq === Frequency.YEARLY &&
      recurrenceConfig.bymonth !== values.months
    ) {
      newConfig = {
        ...newConfig,
        bymonth: values.months
      }
    }
    if (
      (recurrenceConfig.freq === Frequency.YEARLY ||
        recurrenceConfig.freq === Frequency.MONTHLY) &&
      values.repeatType === 'monthDay' &&
      recurrenceConfig.bymonthday !== startDate.getDate()
    ) {
      newConfig = {
        ...newConfig,
        bysetpos: undefined,
        byweekday: undefined,
        bymonthday: startDate.getDate()
      }
    }
    if (
      (recurrenceConfig.freq === Frequency.YEARLY ||
        recurrenceConfig.freq === Frequency.MONTHLY) &&
      values.repeatType === 'weekDay' &&
      recurrenceConfig.byweekday !== translateWeekDay(startDate.getDay())
    ) {
      newConfig = {
        ...newConfig,
        bysetpos: getWeekOfMonth(startDate) - 1,
        bymonthday: undefined,
        byweekday: translateWeekDay(startDate.getDay())
      }
    }

    if (JSON.stringify(newConfig) !== JSON.stringify(recurrenceConfig)) {
      setRecurrenceConfig(newConfig)
    }
  }, [recurrenceConfig, startDate, values])

  return {
    dates,
    changeFrequency,
    changeStartDate
  }
}
