import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCalendarLegendsCastItems } from './CalendarLegendsCast.utils'

export function CalendarLegendsCast() {
  const items = useCalendarLegendsCastItems()

  return (
    <Stack direction="row" gap={{ xs: 0.5, sm: 0.75 }} flexWrap="wrap">
      {items.map(item => (
        <Chip
          key={item.label}
          label={
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body2" fontWeight="medium" color="white">
                {item.label}
              </Typography>
            </Stack>
          }
          size="small"
          sx={{
            background: item.color
          }}
        />
      ))}
    </Stack>
  )
}
