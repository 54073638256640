import { faGear } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/system/useTheme'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { ToolbarProps } from 'react-big-calendar'
import { localStorageClient } from 'utils/dom'
import { useTimeZone } from 'utils/timeZone'
import { useEvents } from '../EventsContext'
import { getUserTimeZone } from '../timeZone/TimeZone.utils'
import { SButton } from './Toolbar.styled'
import { ToolbarSettingsModal } from './ToolbarSettingsModal'
import { MonthSelector } from './monthSelector/MonthSelector'

export function Toolbar({ date }: ToolbarProps) {
  const { t } = useTranslation(['calendar'])

  const { setCurrentMonth, range } = useEvents()

  const { displayedTimeZone, changeTimeZone } = useTimeZone()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))
  const isMediumDevice = useMediaQuery(breakpoints.down('md'))
  const isLargeDevice = useMediaQuery(breakpoints.down('lg'))

  const { query, push } = useRouter()

  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)

  const activeView = query.view as string

  const changeView = (view: string) => {
    if (!range.start || !range.end) return

    const newData = {
      start: new Date(range.start).getTime(),
      end: new Date(range.end).getTime(),
      view
    }

    localStorageClient.setItem('calendarParams', JSON.stringify(newData))
    push(
      {
        query: newData
      },
      undefined,
      {
        shallow: true,
        scroll: true
      }
    )
  }

  const content = (
    <Stack
      py={{ xs: 2, sm: 4 }}
      px={{ xs: 1, sm: 4 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={6}
        sx={{ width: { xs: '20%', sm: '35%' }, maxWidth: 300 }}
      >
        <Button onClick={setCurrentMonth}>
          <Typography variant="body2" color="inherit">
            {t('calendar:labels.today')}
          </Typography>
        </Button>

        {!isMediumDevice && (
          <FormControlLabel
            checked={displayedTimeZone === undefined}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any) => {
              changeTimeZone(
                event.target.checked ? undefined : getUserTimeZone()
              )
            }}
            control={<Switch />}
            label={
              <Typography variant="body2" noWrap>
                {t('calendar:timeZone.switch')}
              </Typography>
            }
          />
        )}
      </Stack>

      <MonthSelector activeDate={date} />

      {!isMediumDevice ? (
        <Stack sx={{ width: '35%', maxWidth: 300 }}>
          <ButtonGroup sx={{ marginLeft: 'auto' }}>
            <Button
              variant={activeView !== 'month' ? 'contained' : 'outlined'}
              onClick={() => changeView('agenda')}
              size="small"
            >
              {t('calendar:labels.agenda')}
            </Button>
            <Button
              variant={activeView === 'month' ? 'contained' : 'outlined'}
              onClick={() => changeView('month')}
              size="small"
            >
              {t('calendar:labels.month')}
            </Button>
          </ButtonGroup>
        </Stack>
      ) : (
        <>
          <Stack width="20%">
            <SButton
              variant="contained"
              size="small"
              onClick={() => setSettingsModalOpen(true)}
            >
              <FontAwesomeIcon icon={faGear} fontSize="1rem" />
            </SButton>
          </Stack>

          <ToolbarSettingsModal
            isOpened={isSettingsModalOpen}
            onClose={() => setSettingsModalOpen(false)}
          />
        </>
      )}
    </Stack>
  )

  if (isLargeDevice) {
    return (
      <Paper elevation={3} sx={{ mb: isSmallDevice ? 2 : 4 }}>
        {content}
      </Paper>
    )
  } else {
    return content
  }
}
