import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import * as dates from 'date-arithmetic'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { useEventTimeZone, useTimeZone } from 'utils/timeZone'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Accessors = any

export function rangeFunc(start: Date, end: Date, unit: dates.Unit = 'day') {
  let current = start
  const days = []
  while (dates.lte(current, end, unit)) {
    days.push(current)
    current = dates.add(current, 1, unit)
  }
  return days
}

export const getLocations = (event: CalendarEvent) => {
  if (event.eventType !== 'process' || !event.tourProcesses) {
    return []
  }
  const locationNames = event.tourProcesses
    .filter(process => process.place?.name)
    .map(process => process.place?.name as string)

  return locationNames.filter(
    (item, index) => locationNames.indexOf(item) === index
  )
}

export function useInRange() {
  const getEventTimeZone = useEventTimeZone()

  const { displayedTimeZone } = useTimeZone()

  return (event: CalendarEvent, start: Date, end: Date) => {
    const timeZone =
      event.place?.timeZone && displayedTimeZone === undefined
        ? event.place?.timeZone
        : getEventTimeZone(event)

    const eStart = utcToZonedTime(event.start, timeZone)
    const eEnd = utcToZonedTime(event.end, timeZone)

    const startsBeforeEnd = dates.lte(eStart, end, 'day')
    const endsAfterStart = !dates.eq(eStart, eEnd, 'minutes')
      ? dates.gt(eEnd, start, 'minutes')
      : dates.gte(eEnd, start, 'minutes')
    return startsBeforeEnd && endsAfterStart
  }
}
