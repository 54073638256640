import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { PropsWithChildren } from 'react'
import { a11yProps } from '../Recurrence.utils'

interface RecurrenceTabPanelProps extends PropsWithChildren {
  index: number
  activeTab: number
}

export function RecurrenceTabPanel({
  children,
  activeTab,
  index
}: RecurrenceTabPanelProps) {
  const a11 = a11yProps(index)
  return (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={a11['aria-controls']}
      aria-labelledby={a11['id']}
    >
      {activeTab === index && (
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <Stack gap={2.5}>{children} </Stack>
        </Box>
      )}
    </div>
  )
}
