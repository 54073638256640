import {
  faCalendarCirclePlus,
  faFilter,
  faRefresh
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Filters } from '../filters/Filter'
import { CalendarActionsView } from './CalendarActions.utils'
import { SButton } from './CalendarActionsDesktop.styled'

export function CalendarActionsDesktop({
  onRefresh,
  isRefreshAvailable,
  isRefreshing,
  onPersonalEventCreate
}: CalendarActionsView) {
  const { t } = useTranslation(['calendar'])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack gap={1}>
      <Stack gap={1} direction="row" justifyContent="end">
        <Tooltip title={t('calendar:actions.refresh')} arrow>
          <SButton
            variant="outlined"
            onClick={onRefresh}
            disabled={isRefreshing || !isRefreshAvailable}
          >
            <FontAwesomeIcon
              icon={faRefresh}
              spin={isRefreshing}
              fontSize="1rem"
            />
          </SButton>
        </Tooltip>

        <Tooltip title={t('calendar:actions.filters')} arrow>
          <SButton
            variant="outlined"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
          >
            <FontAwesomeIcon icon={faFilter} fontSize="1rem" />
          </SButton>
        </Tooltip>

        <Tooltip title={t('calendar:actions.addPersonalEvent')} arrow>
          <SButton onClick={() => onPersonalEventCreate()} variant="contained">
            <FontAwesomeIcon icon={faCalendarCirclePlus} fontSize="1.25rem" />
          </SButton>
        </Tooltip>
      </Stack>

      <Filters open={open} handleClose={handleClose} anchorEl={anchorEl} />
    </Stack>
  )
}
