import { useQueryClient } from '@tanstack/react-query'
import {
  CalendarStartDTO,
  SessionResourceDTO
} from 'api/generated/dotu/dotu.schemas'
import {
  getGetApiSessionQueryKey,
  useGetApiSession
} from 'api/generated/dotu/session'
import {
  getGetApiStartCalendarQueryKey,
  useGetApiStartCalendar
} from 'api/generated/dotu/start'

export const useAccountsData = () => {
  const { data, isLoading } = useGetApiStartCalendar()
  const { data: sessionData, isLoading: isLoadingSessionData } =
    useGetApiSession()
  const { companies, resources } = data || {}

  return {
    companies,
    resources,
    sessionData,
    isLoading: isLoading || isLoadingSessionData
  }
}

export const useUpdateAccountsCache = () => {
  const queryClient = useQueryClient()

  const updateData = (key: readonly string[], newData: SessionResourceDTO) => {
    const previousData = queryClient.getQueryData(key) as CalendarStartDTO

    const index = previousData.resources?.findIndex(
      resource => resource.id === newData.id
    )

    const resources = previousData.resources ? [...previousData.resources] : []

    if (index !== undefined) {
      resources[index] = newData
    } else {
      resources.push(newData)
    }

    queryClient.setQueryData(key, (old?: CalendarStartDTO) => ({
      ...old,
      resources
    }))
  }

  return (newData: SessionResourceDTO) => {
    updateData(getGetApiStartCalendarQueryKey(), newData)
    updateData(getGetApiSessionQueryKey(), newData)
  }
}
