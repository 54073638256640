import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { FC } from 'react'

interface PersonalEventDeleteRecurrenceOptionProps {
  isSelected: boolean
  label: string
  subLabel?: string
  onSelect: () => void
}

export const PersonalEventDeleteRecurrenceOption: FC<
  PersonalEventDeleteRecurrenceOptionProps
> = ({ isSelected, label, subLabel, onSelect }) => {
  const { palette, typography } = useTheme()

  return (
    <Stack
      px={1.25}
      py={1}
      sx={{
        border: '1.5px solid',
        borderColor: isSelected ? palette.primary.main : palette.grey[400],
        borderRadius: 1,
        cursor: 'pointer'
      }}
      direction="row"
      alignItems="center"
      gap={1.5}
      onClick={onSelect}
      width="100%"
    >
      <Stack direction="column" alignItems="start">
        <Typography
          variant="body2"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {label}
        </Typography>

        {subLabel && (
          <Typography
            variant="caption"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{ opacity: typography.opacity.medium }}
          >
            {subLabel}
          </Typography>
        )}
      </Stack>

      <Radio checked={isSelected} sx={{ ml: 'auto' }} />
    </Stack>
  )
}
