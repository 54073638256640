import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useCalendarLegendsProcessItems } from './CalendarLegendsProcess.utils'

interface CalendarLegendsProcessProps {
  isSmallDevice: boolean
}

export function CalendarLegendsProcess({
  isSmallDevice
}: CalendarLegendsProcessProps) {
  const { palette } = useTheme()
  const items = useCalendarLegendsProcessItems()

  return (
    <Stack direction="row" gap={{ xs: 0.5, sm: 0.75 }} flexWrap="wrap">
      {items.map(item => (
        <Tooltip key={item.label} title={item.tooltip}>
          <Chip
            label={
              <Stack direction="row" gap={1} alignItems="center">
                {!isSmallDevice && (
                  <FontAwesomeIcon
                    icon={item.icon}
                    color={palette.text.secondary}
                  />
                )}
                <Typography variant="body2" fontWeight="medium" color="white">
                  {item.label}
                </Typography>
              </Stack>
            }
            size={isSmallDevice ? 'small' : 'medium'}
            sx={{
              background: item.color
            }}
          />
        </Tooltip>
      ))}
    </Stack>
  )
}
