import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { ModalBase } from 'components/modals/ModalBase'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { localStorageClient } from 'utils/dom'
import { useTimeZone } from 'utils/timeZone'
import { useEvents } from '../EventsContext'
import { getUserTimeZone } from '../timeZone/TimeZone.utils'

interface ToolbarSettingsModalProps {
  isOpened: boolean
  onClose: () => void
}

export function ToolbarSettingsModal({
  isOpened,
  onClose
}: ToolbarSettingsModalProps) {
  const { t } = useTranslation(['calendar'])

  const { range } = useEvents()

  const { displayedTimeZone, changeTimeZone } = useTimeZone()

  const { query, push } = useRouter()
  const activeView = query.view as string

  const changeView = (view: string) => {
    if (!range.start || !range.end) return

    const newData = {
      start: new Date(range.start).getTime(),
      end: new Date(range.end).getTime(),
      view
    }

    localStorageClient.setItem('calendarParams', JSON.stringify(newData))
    push(
      {
        query: newData
      },
      undefined,
      {
        shallow: true,
        scroll: true
      }
    )
  }

  return (
    <ModalBase
      isOpened={isOpened}
      onClose={onClose}
      title={t('calendar:settings.title')}
    >
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h6">{t('calendar:settings.view')}</Typography>

          <ButtonGroup>
            <Button
              variant={activeView !== 'month' ? 'contained' : 'outlined'}
              onClick={() => changeView('agenda')}
              size="small"
            >
              {t('calendar:labels.agenda')}
            </Button>
            <Button
              variant={activeView === 'month' ? 'contained' : 'outlined'}
              onClick={() => changeView('month')}
              size="small"
            >
              {t('calendar:labels.month')}
            </Button>
          </ButtonGroup>
        </Stack>

        <Stack>
          <Typography variant="h6">
            {t('calendar:settings.timezone')}
          </Typography>

          <FormControlLabel
            checked={displayedTimeZone === undefined}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any) =>
              changeTimeZone(
                event.target.checked ? undefined : getUserTimeZone()
              )
            }
            control={<Switch size="medium" />}
            label={
              <Typography fontWeight="medium">
                {t('calendar:timeZone.switch')}
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </ModalBase>
  )
}
