import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useEvents } from '../EventsContext'
import { PersonalEventDetailModal } from '../personalEvent/personalEventDetail/PersonalEventDetailModal'
import { CalendarActionsDesktop } from './CalendarActionsDesktop'
import { CalendarActionsMobile } from './CalendarActionsMobile'

export function CalendarActions() {
  const { t } = useTranslation(['calendar'])

  const [isPersonalEventOpened, setPersonalEventOpened] = useState(false)

  const { isSuccess, refetch } = useEvents()

  const [isRefreshing, setRefreshing] = useState(false)
  const [isRefreshAvailable, setRefreshAvailable] = useState(true)

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('lg'))

  useEffect(() => {
    if (isRefreshing && isSuccess) {
      setRefreshing(false)
      toast.success(t('calendar:refreshSuccess'))
    }
  }, [isRefreshing, isSuccess, t])

  const handleRefresh = () => {
    if (isRefreshAvailable) {
      setRefreshing(true)
      setRefreshAvailable(false)
      setTimeout(() => {
        setRefreshAvailable(true)
      }, 3000)
      refetch()
    }
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isSmallDevice ? (
        <CalendarActionsMobile
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
          isRefreshAvailable={isRefreshAvailable}
          onPersonalEventCreate={() => setPersonalEventOpened(true)}
        />
      ) : (
        <CalendarActionsDesktop
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
          isRefreshAvailable={isRefreshAvailable}
          onPersonalEventCreate={() => setPersonalEventOpened(true)}
        />
      )}

      <PersonalEventDetailModal
        isOpened={isPersonalEventOpened}
        onClose={() => setPersonalEventOpened(false)}
      />
    </>
  )
}
