import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useDateFns } from 'utils/date'
import { MonthSelectorDialogItem } from './MonthSelectorDialogItem'

interface MonthSelectorDialogProps {
  activeDate: Date
  onClose: () => void
}

const currentYear = new Date().getFullYear()

export function MonthSelectorDialog({
  activeDate,
  onClose
}: MonthSelectorDialogProps) {
  const dateFns = useDateFns()

  const [year, setYear] = useState(dateFns.getYear(activeDate))

  const handleYearChange = (type: 'next' | 'previous') => {
    if (type === 'next') {
      setYear(year + 1)
    } else {
      setYear(year - 1)
    }
  }

  return (
    <Box maxWidth="350px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        p={0.5}
        gap={2}
      >
        <IconButton
          onClick={() => handleYearChange('previous')}
          disabled={year < currentYear - 4}
        >
          <FontAwesomeIcon icon={faChevronLeft} fontSize="1rem" />
        </IconButton>
        <Typography fontWeight="bold">{year}</Typography>
        <IconButton
          onClick={() => handleYearChange('next')}
          disabled={year > currentYear + 4}
        >
          <FontAwesomeIcon icon={faChevronRight} fontSize="1rem" />
        </IconButton>
      </Stack>

      <Divider />

      <Stack
        direction="row"
        flexWrap="wrap"
        py={1}
        px={3}
        rowGap={3}
        columnGap={4}
      >
        {[...Array(12)].map((_, index) => {
          const startDate = new Date(year, index, 1, 0, 0, 0, 0)

          return (
            <MonthSelectorDialogItem
              key={startDate.toISOString()}
              isActive={startDate.getTime() === activeDate.getTime()}
              onClose={onClose}
              startDate={startDate}
            />
          )
        })}
      </Stack>
    </Box>
  )
}
