import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { CalendarLegendsCast } from './cast/CalendarLegendsCast'
import { CalendarLegendsProcess } from './process/CalendarLegendsProcess'

interface CalendarLegendsProps {
  setLegendDisplayed: (displayed: boolean) => void
}

export function CalendarLegends({ setLegendDisplayed }: CalendarLegendsProps) {
  const { t } = useTranslation('calendar')

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  useEffect(() => {
    if (!isSmallDevice) {
      setLegendDisplayed(true)
    }
  }, [isSmallDevice, setLegendDisplayed])

  if (isSmallDevice) {
    return (
      <Alert
        severity="info"
        variant="outlined"
        onClose={() => setLegendDisplayed(false)}
        sx={{
          borderColor: 'text.disabled'
        }}
      >
        <AlertTitle>{t('legend.title.main')}</AlertTitle>

        <Box mt={1} mb={2}>
          <Typography variant="body2" fontWeight="bold" mb={0.5}>
            {t('legend.title.process')}
          </Typography>
          <CalendarLegendsProcess isSmallDevice={isSmallDevice} />
        </Box>

        <Box mb={1}>
          <Typography variant="body2" fontWeight="bold" mb={0.5}>
            {t('legend.title.cast')}
          </Typography>
          <CalendarLegendsCast />
        </Box>
      </Alert>
    )
  }

  return (
    <Stack gap={1}>
      <Typography variant="body2" fontWeight="medium">
        {t('legend.title.full')}
      </Typography>
      <CalendarLegendsProcess isSmallDevice={isSmallDevice} />
    </Stack>
  )
}
