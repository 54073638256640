import {
  faCheck,
  faHourglassStart,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { CastStatus } from 'utils/calendarStatus'
import { assertUnreachable } from 'utils/types'

export const useProcessButtonVariant = (castStatus: CastStatus) => {
  const { t } = useTranslation(['app'])
  const { palette } = useTheme()

  switch (castStatus) {
    case 'DECLINED':
      return {
        color: palette.castStatus.declined,
        label: t('app:actions.declined'),
        icon: faXmark
      }
    case 'ACCEPTED':
      return {
        color: palette.castStatus.accepted,
        label: t('app:actions.accepted'),
        icon: faHourglassStart
      }
    case 'CONFIRMED':
      return {
        color: palette.castStatus.confirmed,
        label: t('app:actions.confirmed'),
        icon: faCheck
      }
    case 'PENDING':
      return {
        color: palette.castStatus.pending,
        label: '',
        icon: faQuestion
      }
    default:
      assertUnreachable(castStatus as never)
  }
}
