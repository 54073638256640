import {
  CalendarEvent,
  ProcessEventSingle
} from 'api/dotu/calendar/calendarList.utils'
import { useAgendaHeaderItems } from '../../AgendaHeader.utils'
import { AgendaRowActionsPersonal } from './AgendaRowActionsPersonal'
import { AgendaRowActionsProcess } from './process/AgendaRowActionsProcess'

interface AgendaRowActionsProps {
  event: CalendarEvent
  tourEvent?: ProcessEventSingle
}

export function AgendaRowActions({ event, tourEvent }: AgendaRowActionsProps) {
  const config = useAgendaHeaderItems()

  return (
    <td
      css={{
        minWidth: config[5].width,
        width: config[5].width,
        maxWidth: config[5].width,
        boxSizing: 'border-box'
      }}
    >
      {event.eventType === 'personalEvent' && (
        <AgendaRowActionsPersonal event={event} />
      )}

      {(event.eventType === 'tourProcess' || event.eventType === 'process') && (
        <AgendaRowActionsProcess event={event} tourEvent={tourEvent} />
      )}
    </td>
  )
}
