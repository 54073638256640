import { faMasksTheater } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CalendarProcedureDTO,
  PartDTO,
  ProcedureDTO
} from 'api/generated/dotu/dotu.schemas'
import { useAgendaHeaderItems } from '../AgendaHeader.utils'

interface AgendaRowProcedureProps {
  procedure?: ProcedureDTO | CalendarProcedureDTO
  part?: PartDTO
}

export function AgendaRowProcedure({
  procedure,
  part
}: AgendaRowProcedureProps) {
  const config = useAgendaHeaderItems()

  return (
    <td
      css={{
        minWidth: config[4].width,
        width: config[4].width,
        maxWidth: config[4].width,
        boxSizing: 'border-box'
      }}
    >
      {(procedure?.name || part?.name) && (
        <Stack direction="row" gap={1.5} alignItems="center">
          <FontAwesomeIcon
            icon={faMasksTheater}
            fontSize="1.25rem"
            opacity="0.6"
          />

          <Stack>
            {procedure?.name && (
              <Typography
                fontWeight="medium"
                sx={{
                  opacity: 0.87,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {procedure.name}
              </Typography>
            )}

            {part?.name && (
              <Typography
                variant={procedure?.name ? 'body2' : 'body1'}
                sx={{
                  opacity: procedure?.name ? 0.7 : 0.87,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {part?.name}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </td>
  )
}
