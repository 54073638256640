import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useFilters } from '../FiltersContext'
import { FiltersActiveCollapsed } from './FiltersActiveCollapsed'
import { FiltersActiveExpanded } from './FiltersActiveExpanded'

export function FiltersActive() {
  const { t } = useTranslation(['calendar'])

  const [isExpanded, setIsExpanded] = useState(false)

  const { activeFilters } = useFilters()
  const { data, isLoading } = useGetApiStartCalendar()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const isAllSelected =
    !activeFilters ||
    isLoading ||
    (activeFilters.eventTypes?.length === 3 &&
      activeFilters.processStatuses?.length === 3 &&
      activeFilters.castStatuses?.length === 4 &&
      activeFilters.resourceIds?.length === data?.resources?.length)

  if (isAllSelected || isSmallDevice) return null

  return (
    <Stack
      direction="row"
      mt={1}
      gap={1}
      maxWidth="50vw"
      flexWrap="wrap"
      justifyContent="end"
    >
      {isExpanded ? <FiltersActiveExpanded /> : <FiltersActiveCollapsed />}

      <Button onClick={() => setIsExpanded(!isExpanded)}>
        <Typography variant="caption">
          {isExpanded
            ? t('calendar:filters.showLess')
            : t('calendar:filters.showMore')}
        </Typography>
      </Button>
    </Stack>
  )
}
