import { faUser as faUserRegular } from '@fortawesome/pro-regular-svg-icons'
import {
  faBuildingColumns,
  faBus,
  faMasksTheater,
  faTicket,
  faUmbrellaBeach,
  faUser as faUserSolid
} from '@fortawesome/pro-solid-svg-icons'
import { useGetApiSession } from 'api/generated/dotu/session'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { useTranslation } from 'next-i18next'
import { useFilters } from '../FiltersContext'
import {
  useCastStatusLabel,
  useProcessStatusLabel,
  useResourceLabel
} from './FiltersActive.utils'
import { FiltersActiveItem } from './FiltersActiveItem'

export function FiltersActiveExpanded() {
  const { t } = useTranslation(['calendar', 'app'])

  const { activeFilters } = useFilters()
  const { isLoading: isLoadingStart } = useGetApiStartCalendar()
  const { data: dataSession, isLoading: isLoadingSession } = useGetApiSession()

  const getResourceLabel = useResourceLabel()
  const getCastStatusLabel = useCastStatusLabel()
  const getProcessStatusLabel = useProcessStatusLabel()

  if (isLoadingStart || isLoadingSession) return null

  return (
    <>
      {activeFilters?.eventTypes?.map(type => (
        <FiltersActiveItem
          key={type}
          parent="eventTypes"
          value={type}
          label={
            type === 'personalEvent'
              ? t('calendar:filters.values.personalEvent')
              : type === 'process'
              ? t('calendar:filters.values.process')
              : t('calendar:filters.values.tour')
          }
          icon={
            type === 'personalEvent'
              ? faUmbrellaBeach
              : type === 'process'
              ? faTicket
              : faBus
          }
        />
      ))}

      {activeFilters?.processStatuses?.map(status => (
        <FiltersActiveItem
          parent="processStatuses"
          value={status}
          key={status}
          label={getProcessStatusLabel(status)}
          icon={faBuildingColumns}
        />
      ))}

      {activeFilters?.castStatuses?.map(status => (
        <FiltersActiveItem
          key={status}
          parent="castStatuses"
          value={status}
          label={getCastStatusLabel(status)}
          icon={faMasksTheater}
        />
      ))}

      {activeFilters?.resourceIds?.map(id => (
        <FiltersActiveItem
          key={id}
          parent="resourceIds"
          value={id}
          label={getResourceLabel(id)}
          icon={
            dataSession?.resourcesManaged?.find(
              resource => resource.id === parseInt(id.toString())
            )
              ? faUserRegular
              : faUserSolid
          }
        />
      ))}
    </>
  )
}
