import Stack from '@mui/material/Stack'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { PersonalEventDeleteRecurrenceOption } from './PersonalEventDeleteRecurrenceOption'

interface PersonalEventDeleteRecurrenceProps {
  startDate: Date
  activeType: 'single' | 'multiple'
  setType: (type: 'single' | 'multiple') => void
}

export const PersonalEventDeleteRecurrence: FC<
  PersonalEventDeleteRecurrenceProps
> = ({ activeType, setType, startDate }) => {
  const { t } = useTranslation(['personalEvent'])

  return (
    <Stack gap={1} width="100%" alignItems="center">
      <Stack gap={1.5} width="100%" maxWidth={300}>
        <PersonalEventDeleteRecurrenceOption
          isSelected={activeType === 'single'}
          onSelect={() => setType('single')}
          label={t('personalEvent:delete.type.single')}
          subLabel={startDate.toLocaleString()}
        />
        <PersonalEventDeleteRecurrenceOption
          isSelected={activeType === 'multiple'}
          onSelect={() => setType('multiple')}
          label={t('personalEvent:delete.type.multiple')}
        />
      </Stack>
    </Stack>
  )
}
