import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import { Loader } from 'components/loader/Loader'
import { getDaysInMonth } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import addMonths from 'date-fns/addMonths'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import { useTranslation } from 'next-i18next'
import { DateLocalizer, ViewStatic } from 'react-big-calendar'
import { useEvents } from 'sections/calendar/EventsContext'
import { useDateFns } from 'utils/date'
import { useEventTimeZone, useTimeZone } from 'utils/timeZone'
import { STable } from './Agenda.styled'
import { Accessors, rangeFunc, useInRange } from './Agenda.utils'
import { AgendaHeader } from './AgendaHeader'
import { AgendaCard } from './card/AgendaCard'
import { AgendaRow } from './row/AgendaRow'

interface AgendaProps extends ViewStatic {
  accessors: Accessors
  localizer: DateLocalizer
  date: Date
  events: CalendarEvent[]
}

export function Agenda({ accessors, localizer, date, events }: AgendaProps) {
  const { t } = useTranslation('calendar')
  const dateFns = useDateFns()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('lg'))

  const getEventTimeZone = useEventTimeZone()

  const { displayedTimeZone } = useTimeZone()

  const { isLoading, isError } = useEvents()

  const inRange = useInRange()

  const getEventKey = (event: CalendarEvent, day: Date) => {
    if (event.tourId) {
      if (event?.cast && 'id' in event.cast && event.cast.id) {
        return (
          'tour_' + event.id + '_' + day.toISOString() + '_' + event.cast.id
        )
      }
      return 'tour_' + event.id + '_' + day.toISOString()
    }
    if (event.id && event?.cast && 'id' in event.cast && event.cast.id) {
      return (
        'process_' + event.id + '_' + day.toISOString() + '_' + event.cast.id
      )
    }
    return 'personal_' + event.id + '_' + day.toISOString()
  }

  const renderDay = (day: Date, events: CalendarEvent[]) => {
    events = events.filter(e => inRange(e, startOfDay(day), endOfDay(day)))

    return (
      <>
        {events.map((event, idx) => {
          return isSmallDevice ? (
            <Box
              key={getEventKey(event, day)}
              maxWidth="425px"
              width="100%"
              margin="0 auto"
            >
              <AgendaCard
                index={idx}
                day={day}
                event={event}
                localizer={localizer}
                timeRangeLabel={timeRangeLabel(event)}
              />
            </Box>
          ) : (
            <AgendaRow
              key={getEventKey(event, day)}
              index={idx}
              day={day}
              event={event}
              localizer={localizer}
              size={events.length}
              timeRangeLabel={timeRangeLabel(event)}
            />
          )
        })}

        {isSmallDevice && events.length > 0 && (
          <Box maxWidth="425px" width="100%" margin="0 auto">
            <Divider sx={{ mt: 1 }} />
          </Box>
        )}
      </>
    )
  }

  const timeRangeLabel = (event: CalendarEvent) => {
    const timeZone =
      event.place?.timeZone && displayedTimeZone === undefined
        ? event.place?.timeZone
        : getEventTimeZone(event)

    const end = utcToZonedTime(event.end, timeZone)
    const start = utcToZonedTime(event.start, timeZone)

    if (!accessors.allDay(event)) {
      if (end.getDay() === start.getDay()) {
        const timePeriod = `${dateFns.format(
          start,
          'fullTime'
        )} – ${dateFns.format(end, 'fullTime')}`
        return timePeriod
      }
    }
    const startDate = dateFns.format(start, 'fullDateTime')
    const endDate = dateFns.format(end, 'fullDateTime')
    return `${startDate} – ${endDate}`
  }

  const monthDays = getDaysInMonth(date) - 1
  const end = new Date(dateFns.addDays(date, monthDays).setHours(0, 0, 0, 0))
  const range = rangeFunc(date, end, 'day')

  const renderState = () => {
    if (isLoading) {
      return (
        <Stack justifyContent="center" alignItems="center" textAlign="center">
          <Loader size={isSmallDevice ? 40 : 64} />
        </Stack>
      )
    } else if (isError) {
      return (
        <>
          <Typography variant="h6" color="error">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              css={{ marginRight: '12px' }}
            />
            {t('agenda.error.title')}
          </Typography>
          <Typography variant="body2">{t('agenda.error.subtitle')}</Typography>
        </>
      )
    } else {
      return (
        <Typography variant="h6" color="text.disabled">
          {t('agenda.empty')}
        </Typography>
      )
    }
  }

  if (isSmallDevice) {
    return (
      <div>
        {events.length === 0 ? (
          <Box padding={4} textAlign="center">
            {renderState()}
          </Box>
        ) : (
          <Stack gap={2}>{range.map(day => renderDay(day, events))}</Stack>
        )}
      </div>
    )
  } else {
    return (
      <STable>
        <AgendaHeader />

        <tbody>
          {events.length === 0 ? (
            <tr>
              <td
                colSpan={7}
                css={{ border: 'none !important', height: '25vh' }}
              >
                <Box padding={4} textAlign="center">
                  {renderState()}
                </Box>
              </td>
            </tr>
          ) : (
            range.map(day => renderDay(day, events))
          )}
        </tbody>
      </STable>
    )
  }
}

Agenda.title = (start: Date) => {
  return start
}

Agenda.navigate = (date: Date, action: 'NEXT' | 'PREV') => {
  const startDate = startOfMonth(date)
  switch (action) {
    case 'PREV':
      return addMonths(startDate, -1)
    case 'NEXT':
      return addMonths(startDate, 1)
    default:
      return date
  }
}
