import { ModalBase } from 'components/modals/ModalBase'
import { useTranslation } from 'next-i18next'
import { FiltersSettings } from '../filters/settings/FiltersSettings'

interface CalendarActionsMobileFiltersProps {
  isOpened: boolean
  onClose: () => void
}

export function CalendarActionsMobileFilters({
  isOpened,
  onClose
}: CalendarActionsMobileFiltersProps) {
  const { t } = useTranslation(['calendar'])

  return (
    <ModalBase
      variant="x-large"
      isOpened={isOpened}
      onClose={onClose}
      title={t('calendar:actions.filters')}
      sx={{ py: 0 }}
    >
      <FiltersSettings />
    </ModalBase>
  )
}
