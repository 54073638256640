import { FC } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { SessionResourceDTO } from 'api/generated/dotu/dotu.schemas'
import { useTranslation } from 'next-i18next'
import { PersonalEventDeleteAccount } from './PersonalEventDeleteAccount'

interface PersonalEventDeleteAccountsProps {
  resources: SessionResourceDTO[]
  allResources: SessionResourceDTO[]
  displayAll: boolean
  activeResources: SessionResourceDTO[]
  setResources: (resources: SessionResourceDTO[]) => void
}

export const PersonalEventDeleteAccounts: FC<
  PersonalEventDeleteAccountsProps
> = ({
  resources,
  activeResources,
  allResources,
  displayAll,
  setResources
}) => {
  const { t } = useTranslation(['personalEvent'])

  const handleSelect = (resource: SessionResourceDTO, selected: boolean) => {
    if (selected) {
      setResources([...activeResources, resource])
    } else {
      setResources(activeResources.filter(r => r.id !== resource.id))
    }
  }

  if (
    (!displayAll && resources.length < 2) ||
    (displayAll && allResources.length < 2)
  )
    return null

  const resourcesToRender = displayAll ? allResources : resources

  return (
    <Stack gap={1} width="100%" alignItems="center">
      <Typography fontWeight="bold">
        {t('personalEvent:delete.accounts')}
      </Typography>

      <Stack gap={1.5} width="100%" maxWidth={300}>
        {resourcesToRender.map(resource => (
          <PersonalEventDeleteAccount
            key={resource.id}
            isSelected={Boolean(
              activeResources.find(r => r.id === resource.id)
            )}
            onSelect={handleSelect}
            resource={resource}
          />
        ))}
      </Stack>
    </Stack>
  )
}
