import {
  faCheck,
  faQuestion,
  faUmbrellaBeach,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'

export const useCalendarLegendsProcessItems = () => {
  const { t } = useTranslation(['calendar'])
  const { palette } = useTheme()

  return [
    {
      label: t('calendar:legend.personalEvent.label'),
      tooltip: t('calendar:legend.personalEvent.tooltip'),
      color: palette.processStatus.personal,
      icon: faUmbrellaBeach
    },
    {
      label: t('calendar:legend.pending.label'),
      tooltip: t('calendar:legend.pending.tooltip'),
      color: palette.processStatus.pending,
      icon: faQuestion
    },
    {
      label: t('calendar:legend.rejected.label'),
      tooltip: t('calendar:legend.rejected.tooltip'),
      color: palette.processStatus.declined,
      icon: faXmark
    },
    {
      label: t('calendar:legend.confirmed.label'),
      tooltip: t('calendar:legend.confirmed.tooltip'),
      color: palette.processStatus.confirmed,
      icon: faCheck
    }
  ]
}
