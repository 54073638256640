import { faPenToSquare, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PersonalEvent } from 'api/dotu/calendar/calendarList.utils'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { PersonalEventDeleteModal } from 'sections/calendar/personalEvent/personalEventDelete/PersonalEventDeleteModal'
import { PersonalEventDetailModal } from 'sections/calendar/personalEvent/personalEventDetail/PersonalEventDetailModal'

interface AgendaRowActionsPersonalProps {
  event: PersonalEvent
}

export function AgendaRowActionsPersonal({
  event
}: AgendaRowActionsPersonalProps) {
  const { t } = useTranslation(['app'])

  const [isEditModalOpened, setEditModalOpened] = useState(false)
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false)

  return (
    <>
      <Stack direction="row" gap={1} justifyContent="center">
        <Button
          size="small"
          color="inherit"
          sx={{ opacity: 0.6 }}
          onClick={() => setEditModalOpened(true)}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon icon={faPenToSquare} />{' '}
            <Typography variant="body2" fontWeight="medium" color="inherit">
              {t('app:actions.edit')}
            </Typography>
          </Stack>
        </Button>

        <Button
          size="small"
          color="inherit"
          sx={{ opacity: 0.6 }}
          onClick={() => setDeleteModalOpened(true)}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon icon={faTrash} />
            <Typography variant="body2" fontWeight="medium" color="inherit">
              {t('app:actions.delete')}
            </Typography>
          </Stack>
        </Button>
      </Stack>

      <PersonalEventDetailModal
        isOpened={isEditModalOpened}
        onClose={() => setEditModalOpened(false)}
        personalEvent={event}
      />

      <PersonalEventDeleteModal
        isOpened={isDeleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        process={event}
      />
    </>
  )
}
