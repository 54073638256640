import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { SessionResourceDTO } from 'api/generated/dotu/dotu.schemas'
import { Avatar } from 'components/avatar/Avatar'
import { useAccountsData } from 'sections/accounts/Accounts.utils'

interface PersonalEventDeleteAccountProps {
  isSelected: boolean
  resource: SessionResourceDTO
  onSelect: (resource: SessionResourceDTO, selected: boolean) => void
}

export function PersonalEventDeleteAccount({
  isSelected,
  resource,
  onSelect
}: Readonly<PersonalEventDeleteAccountProps>) {
  const { companies } = useAccountsData()
  const company = companies?.find(company => company.id === resource.companyId)

  const { palette, typography } = useTheme()
  const handleSelect = () => {
    onSelect(resource, !isSelected)
  }

  return (
    <Stack
      px={1.25}
      py={1}
      sx={{
        border: '1.5px solid',
        borderColor: isSelected ? palette.primary.main : palette.grey[400],
        borderRadius: 1,
        cursor: 'pointer'
      }}
      direction="row"
      alignItems="center"
      gap={1.5}
      onClick={handleSelect}
      width="100%"
    >
      <Avatar name={resource.name} url={resource.photo} size={32} />

      <Stack direction="column" alignItems="start">
        <Typography
          variant="body2"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {resource.name}
        </Typography>

        {company && (
          <Typography
            variant="caption"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            sx={{ opacity: typography.opacity.medium }}
          >
            {company.name}
          </Typography>
        )}
      </Stack>

      <Checkbox checked={isSelected} sx={{ ml: 'auto' }} />
    </Stack>
  )
}
