import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'

export const useCalendarLegendsCastItems = () => {
  const { t } = useTranslation(['app'])
  const { palette } = useTheme()

  return [
    {
      label: t('app:actions.pending'),
      color: palette.castStatus.pending
    },
    {
      label: t('app:actions.accepted'),
      color: palette.castStatus.accepted
    },
    {
      label: t('app:actions.confirmed'),
      color: palette.castStatus.confirmed
    },
    {
      label: t('app:actions.declined'),
      color: palette.castStatus.declined
    }
  ]
}
