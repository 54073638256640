import { faCircle, faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { isDate } from 'utils/types'
import { PersonalEventFormValues } from '../PersonalEventDetailModal.utils'
import { Recurrence } from '../recurrence/Recurrence'

const now = new Date()

interface PersonalEventDetailModalDateModifiersProps {
  isRecurrenceEnabled: boolean
}

export function PersonalEventDetailModalDateModifiers({
  isRecurrenceEnabled
}: PersonalEventDetailModalDateModifiersProps) {
  const { t } = useTranslation(['personalEvent'])

  const [isRecurrenceVisible, setRecurrenceVisible] = useState(false)

  const form = useFormContext<PersonalEventFormValues>()
  const startDate = form.watch('startDate')
  const endDate = form.watch('endDate')
  const isRecurrence = form.watch('isRecurrence')
  const occurrences = form.watch('occurrences')

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 1, sm: 5 }}
        mb={{ xs: 1.5, sm: 0 }}
        justifyContent="center"
      >
        <Controller
          name="isFullDay"
          control={form.control}
          render={({ field: { value, name, onChange, onBlur } }) => (
            <FormControlLabel
              name={name}
              value={value}
              onChange={value => {
                if (value) {
                  form.setValue(
                    'startDate',
                    startDate
                      ? new Date(startDate.setHours(0, 0, 0, 0))
                      : new Date(now.setHours(0, 0, 0, 0))
                  )
                  form.setValue(
                    'endDate',
                    endDate
                      ? new Date(endDate.setHours(23, 59, 59, 999))
                      : new Date(now.setHours(23, 59, 59, 999))
                  )
                }
                onChange(value)
              }}
              onBlur={onBlur}
              control={<Switch />}
              label={t('personalEvent:detail.fields.fullDay')}
            />
          )}
        />

        {isRecurrenceEnabled && (
          <Button
            size="small"
            disabled={!isDate(startDate) || !isDate(endDate)}
            variant={isRecurrence ? 'contained' : 'outlined'}
            onClick={() => setRecurrenceVisible(true)}
          >
            <Stack direction="row" alignItems="center">
              <FontAwesomeIcon icon={isRecurrence ? faCircleCheck : faCircle} />{' '}
              <Box sx={{ ml: 1 }}>
                {t('personalEvent:detail.recurrenceButton')}
                {isRecurrence && occurrences && ` (${occurrences.length})`}
              </Box>
            </Stack>
          </Button>
        )}
      </Stack>

      {isDate(startDate) && (
        <Recurrence
          isOpened={isRecurrenceVisible}
          startDate={startDate || now}
          onClose={() => setRecurrenceVisible(false)}
          onSave={(dates: Date[]) => {
            form.setValue('occurrences', dates)
            form.setValue('isRecurrence', true)
            setRecurrenceVisible(false)
          }}
          onDelete={() => {
            form.setValue('occurrences', [])
            form.setValue('isRecurrence', false)
            setRecurrenceVisible(false)
          }}
        />
      )}
    </>
  )
}
