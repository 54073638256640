import AvatarGroup from '@mui/material/AvatarGroup'
import Stack from '@mui/material/Stack'
import { CalendarEventResource } from 'api/dotu/calendar/calendarList.utils'
import { Avatar } from 'components/avatar/Avatar'
import { useAgendaHeaderItems } from '../AgendaHeader.utils'

interface AgendaRowPersonsProps {
  resources: CalendarEventResource[]
}

export function AgendaRowPersons({ resources }: AgendaRowPersonsProps) {
  const config = useAgendaHeaderItems()

  return (
    <td
      css={{
        minWidth: config[2].width,
        width: config[2].width,
        maxWidth: config[2].width,
        boxSizing: 'border-box'
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <AvatarGroup max={4}>
          {resources?.map(resource => (
            <Avatar
              key={resource.id}
              name={resource.name}
              url={resource.photo}
              tooltip={`
                ${resource.name} (${resource.company?.name})`}
            />
          ))}
        </AvatarGroup>
      </Stack>
    </td>
  )
}
