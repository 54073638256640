import Box from '@mui/material/Box'
import { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import Stack from '@mui/system/Stack'
import { Select } from 'components/form/Select'
import { useTranslation } from 'next-i18next'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDateFns } from 'utils/date'
import { MAX_OCCURRENCES, RecurrenceFormValues } from '../Recurrence.utils'

interface RecurrenceSectionEndProps {
  lastDate: Date
}

export function RecurrenceSectionEnd({ lastDate }: RecurrenceSectionEndProps) {
  const { t } = useTranslation(['personalEvent'])
  const form = useFormContext<RecurrenceFormValues>()
  const dateFns = useDateFns()

  return (
    <Stack>
      <Typography variant="h6" component="h2" mb={0.5}>
        {t('personalEvent:recurrenceDetail.end.title')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 0, sm: 1 }}
        justifyContent="start"
        alignItems={{ xs: 'start', sm: 'center' }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>
            {t('personalEvent:recurrenceDetail.end.label.endAfter')}
          </Typography>

          <Box>
            <Controller
              name="count"
              control={form.control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Select
                  size="small"
                  name={name}
                  onChange={
                    onChange as (
                      event: SelectChangeEvent<unknown>,
                      child: ReactNode
                    ) => void
                  }
                  onBlur={onBlur}
                  value={value}
                  options={[...Array(MAX_OCCURRENCES - 1)].map((_, i) => ({
                    value: i + 2,
                    label: (i + 2).toString()
                  }))}
                />
              )}
            />
          </Box>
        </Stack>

        <Typography>
          {t('personalEvent:recurrenceDetail.end.label.occurrences')}
        </Typography>
      </Stack>

      {lastDate && (
        <Typography
          variant="caption"
          color="text.secondary"
          mt={{ xs: 1, sm: 0 }}
        >
          {t('personalEvent:recurrenceDetail.end.lastDate')}:{' '}
          {dateFns.format(lastDate, 'weekday')}{' '}
          {dateFns.format(lastDate, 'fullDateTime')}
        </Typography>
      )}
    </Stack>
  )
}
