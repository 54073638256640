import { SessionResourceDTO } from 'api/generated/dotu/dotu.schemas'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'
import { localStorageClient } from 'utils/dom'

export interface Filters {
  castStatuses?: ('PENDING' | 'CONFIRMED' | 'ACCEPTED' | 'DECLINED')[]
  processStatuses?: ('PENDING' | 'CONFIRMED' | 'DECLINED')[]
  eventTypes?: ('process' | 'personalEvent' | 'tourProcess')[]
  resourceIds?: number[]
}

interface FiltersContextValue {
  activeFilters?: Filters
  setFilters: (newFilters: Filters) => void
}

const DEFAULT_FILTERS: Filters = {
  castStatuses: ['PENDING', 'CONFIRMED', 'ACCEPTED', 'DECLINED'],
  processStatuses: ['PENDING', 'CONFIRMED', 'DECLINED'],
  eventTypes: ['process', 'tourProcess', 'personalEvent']
}

const FiltersContext = createContext<FiltersContextValue>({
  activeFilters: DEFAULT_FILTERS,
  setFilters: () => undefined
})

const getInitialFilters = (resources?: SessionResourceDTO[] | null) => {
  const filters = localStorageClient.getItem('calendarFilters')

  if (filters) {
    const parsedFilters = JSON.parse(filters)
    if (parsedFilters.resourceIds) {
      return {
        ...parsedFilters,
        resourceIds: parsedFilters.resourceIds.map((resourceId: string) =>
          parseInt(resourceId)
        )
      }
    }
    return parsedFilters
  }
  return {
    ...DEFAULT_FILTERS,
    resourceIds: resources?.map(resource => resource.id)
  }
}

export function FiltersProvider({ children }: PropsWithChildren) {
  const { data } = useGetApiStartCalendar()

  const [filters, setFilters] = useState<Filters | undefined>(
    getInitialFilters(data?.resources)
  )

  const onFiltersChange = (newFilters: Filters) => {
    setFilters(newFilters)
    localStorageClient.setItem('calendarFilters', JSON.stringify(newFilters))
  }

  const filterValues = useMemo(
    () => ({ activeFilters: filters, setFilters: onFiltersChange }),
    [filters]
  )

  return (
    <FiltersContext.Provider value={filterValues}>
      {children}
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  const context = useContext(FiltersContext)
  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersContext')
  }
  return context
}
