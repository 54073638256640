import { faClock } from '@fortawesome/pro-regular-svg-icons'
import { faBuildingColumns, faBus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ProcessEventSingle } from 'api/dotu/calendar/calendarList.utils'
import { PlaceDTO, SessionCompanyDTO } from 'api/generated/dotu/dotu.schemas'
import { useTranslation } from 'next-i18next'
import { DEFAULT_TIMEZONE, useTimeZone } from 'utils/timeZone'
import { useAgendaHeaderItems } from '../AgendaHeader.utils'

interface AgendaRowLocationProps {
  tourEvent?: ProcessEventSingle
  locations: string[]
  timeZone?: string | null
  place?: PlaceDTO
  company?: SessionCompanyDTO
}

export function AgendaRowLocation({
  tourEvent,
  locations,
  timeZone,
  place,
  company
}: AgendaRowLocationProps) {
  const config = useAgendaHeaderItems()

  const { t } = useTranslation(['calendar'])

  const { displayedTimeZone } = useTimeZone()

  const renderContent = () => {
    if (tourEvent && locations.length > 1) {
      return (
        <Tooltip
          title={
            t('calendar:labels.allToursLocations') + ' ' + locations.join(', ')
          }
        >
          <Stack direction="row" gap={1} alignItems="center">
            <FontAwesomeIcon icon={faBus} fontSize="1.25rem" opacity="0.6" />

            <Stack direction="column">
              <Typography
                fontWeight="medium"
                sx={{
                  opacity: 0.87
                }}
              >
                {tourEvent?.place?.name ?? locations[0]}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  opacity: 0.5,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {place?.timeZone && displayedTimeZone === undefined
                  ? place.timeZone
                  : displayedTimeZone
                  ? displayedTimeZone.name
                  : timeZone ?? DEFAULT_TIMEZONE.name}
              </Typography>
            </Stack>
          </Stack>
        </Tooltip>
      )
    } else if (place?.name) {
      return (
        <Stack direction="row" gap={1} alignItems="center">
          <FontAwesomeIcon
            icon={tourEvent ? faBus : faBuildingColumns}
            fontSize="1.25rem"
            opacity="0.6"
          />

          <Stack direction="column">
            <Typography
              fontWeight="medium"
              sx={{
                opacity: 0.87
              }}
            >
              {locations[0] ?? place.name}
            </Typography>

            {company?.name && (
              <Typography
                variant={place?.name ? 'body2' : 'body1'}
                sx={{
                  opacity: place?.name ? 0.7 : 0.87,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {company?.name}
              </Typography>
            )}

            <Typography
              variant="body2"
              sx={{
                opacity: 0.5,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {place.timeZone && displayedTimeZone === undefined
                ? place.timeZone
                : displayedTimeZone
                ? displayedTimeZone.name
                : timeZone ?? DEFAULT_TIMEZONE.name}
            </Typography>
          </Stack>
        </Stack>
      )
    }
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <FontAwesomeIcon icon={faClock} fontSize="1.25rem" opacity="0.6" />

        <Typography
          variant="body2"
          fontWeight="medium"
          sx={{
            opacity: 0.7
          }}
        >
          {displayedTimeZone
            ? displayedTimeZone.name
            : timeZone ?? DEFAULT_TIMEZONE.name}
        </Typography>
      </Stack>
    )
  }

  return (
    <td
      css={{
        minWidth: config[3].width,
        width: config[3].width,
        maxWidth: config[3].width,
        boxSizing: 'border-box'
      }}
    >
      {renderContent()}
    </td>
  )
}
