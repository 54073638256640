import {
  faCalendarCirclePlus,
  faCommentLines,
  faFilter,
  faRefresh
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@mui/material/Badge'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { FeedbackModal } from 'components/layout/app/feedbackModal/FeedbackModal'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'
import { useFilters } from '../filters/FiltersContext'
import { CalendarActionsView } from './CalendarActions.utils'
import { SWrapper } from './CalendarActionsMobile.styled'
import { CalendarActionsMobileFilters } from './CalendarActionsMobileFilters'

const mobileMenuHeight = 64

export function CalendarActionsMobile({
  onRefresh,
  isRefreshAvailable,
  isRefreshing,
  onPersonalEventCreate
}: CalendarActionsView) {
  const { t } = useTranslation(['calendar'])

  const { typography, palette } = useTheme()

  const { activeFilters } = useFilters()
  const { data, isLoading } = useGetApiStartCalendar()

  const isAllSelected =
    !activeFilters ||
    isLoading ||
    (activeFilters.eventTypes?.length === 2 &&
      activeFilters.processStatuses?.length === 3 &&
      activeFilters.castStatuses?.length === 4 &&
      activeFilters.resourceIds?.length === data?.resources?.length)

  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false)

  const prevScrollY = useRef(0)
  const [isScrollingDown, setIsScrollingDown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY < prevScrollY.current && isScrollingDown) {
        setIsScrollingDown(false)
      } else if (currentScrollY > prevScrollY.current && !isScrollingDown) {
        setIsScrollingDown(true)
      }

      // when flicking to the top, the browser rubber bands
      // which must not be interpreted as scrolling down
      prevScrollY.current = Math.max(0, currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isScrollingDown])

  const IS_ANIMATION_DISABLED = true

  return (
    <>
      <SWrapper
        initial={{ bottom: 0 }}
        animate={{
          bottom:
            isScrollingDown && !IS_ANIMATION_DISABLED
              ? -mobileMenuHeight * 2
              : 0
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <Paper elevation={3} square>
          <Stack
            gap={3}
            direction="row"
            justifyContent="space-between"
            maxWidth={450}
            margin="0 auto"
            height={!IS_ANIMATION_DISABLED ? mobileMenuHeight : undefined}
            alignItems="start"
            px={1}
            py={1.5}
          >
            <Stack
              gap={0.5}
              direction="column"
              alignItems="center"
              textAlign="center"
              onClick={() => setFeedbackModalOpen(true)}
            >
              <FontAwesomeIcon
                icon={faCommentLines}
                fontSize="1.5rem"
                color={palette.primary.main}
                opacity={typography.opacity.high}
              />
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ opacity: typography.opacity.medium }}
              >
                {t('calendar:actions.feedback')}
              </Typography>
            </Stack>

            <Stack
              gap={0.5}
              direction="column"
              alignItems="center"
              textAlign="center"
              onClick={
                !isRefreshing && isRefreshAvailable ? onRefresh : undefined
              }
            >
              <FontAwesomeIcon
                icon={faRefresh}
                spin={isRefreshing}
                fontSize="1.5rem"
                color={palette.primary.main}
                opacity={
                  !isRefreshing && isRefreshAvailable
                    ? typography.opacity.high
                    : typography.opacity.medium
                }
              />
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{
                  opacity:
                    !isRefreshing && isRefreshAvailable
                      ? typography.opacity.medium
                      : typography.opacity.disabled
                }}
              >
                {t('calendar:actions.refreshShort')}
              </Typography>
            </Stack>

            <Stack
              gap={0.5}
              direction="column"
              alignItems="center"
              textAlign="center"
              onClick={() => setFiltersModalOpen(true)}
            >
              <Badge
                color="primary"
                badgeContent={
                  isAllSelected
                    ? 0
                    : (activeFilters?.castStatuses?.length ?? 0) +
                      (activeFilters?.eventTypes?.length ?? 0) +
                      (activeFilters?.processStatuses?.length ?? 0) +
                      (activeFilters?.resourceIds?.length ?? 0)
                }
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  fontSize="1.5rem"
                  color={palette.primary.main}
                  opacity={typography.opacity.high}
                />
              </Badge>
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ opacity: typography.opacity.medium }}
              >
                {t('calendar:actions.filters')}
              </Typography>
            </Stack>

            <Stack
              gap={0.5}
              direction="column"
              alignItems="center"
              textAlign="center"
              onClick={onPersonalEventCreate}
            >
              <FontAwesomeIcon
                icon={faCalendarCirclePlus}
                fontSize="1.5rem"
                color={palette.primary.main}
                opacity={typography.opacity.high}
              />
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ opacity: typography.opacity.medium }}
              >
                {t('calendar:actions.addPersonalEventShort')}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </SWrapper>

      <CalendarActionsMobileFilters
        isOpened={isFiltersModalOpen}
        onClose={() => setFiltersModalOpen(false)}
      />

      <FeedbackModal
        isOpened={isFeedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
      />
    </>
  )
}
