import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useFilters } from '../FiltersContext'
import { FiltersSettingsSection } from './FiltersSettingsSection'

export function FiltersSettings() {
  const { t } = useTranslation(['calendar', 'app'])

  const { activeFilters } = useFilters()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('md'))

  const { data, isLoading } = useGetApiStartCalendar()

  const form = useForm()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultValues: any = {}

    if (activeFilters) {
      for (const [key, value] of Object.entries(activeFilters)) {
        defaultValues[key] = {}

        if (value && Object.keys(value).length) {
          value.forEach((item: string) => {
            defaultValues[key][item] = true
          })
        }
      }
    }

    form.reset(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getResources = () => {
    if (data) {
      return (
        data?.resources
          ?.filter(
            resource => resource.contractPrivacy && resource.contractTerms
          )
          .map(resource => {
            const company = data?.companies?.find(
              company => company.id === resource.companyId
            )

            return {
              value: resource.id ?? 0,
              label: `${resource.name} (${company?.name})`
            }
          }) ?? []
      )
    }
    return []
  }

  if (isLoading) {
    return null
  }

  return (
    <FormProvider {...form}>
      <form>
        <Box p={2} maxWidth="100vw">
          <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
            <FiltersSettingsSection
              title={t('calendar:filters.labels.eventType')}
              name="eventTypes"
              options={[
                {
                  value: 'process',
                  label: t('calendar:filters.values.process')
                },
                {
                  value: 'tourProcess',
                  label: t('calendar:filters.values.tour')
                },
                {
                  value: 'personalEvent',
                  label: t('calendar:filters.values.personalEvent')
                }
              ]}
            />

            <Divider
              orientation={isSmallDevice ? 'horizontal' : 'vertical'}
              flexItem
            />

            <FiltersSettingsSection
              title={t('calendar:filters.labels.processStatus')}
              name="processStatuses"
              options={[
                { value: 'PENDING', label: t('calendar:legend.pending.label') },
                {
                  value: 'CONFIRMED',
                  label: t('calendar:legend.confirmed.label')
                },
                {
                  value: 'DECLINED',
                  label: t('calendar:legend.rejected.label')
                }
              ]}
            />

            <Divider
              orientation={isSmallDevice ? 'horizontal' : 'vertical'}
              flexItem
            />

            <FiltersSettingsSection
              title={t('calendar:filters.labels.castStatus')}
              name="castStatuses"
              options={[
                { value: 'PENDING', label: t('app:actions.pending') },
                { value: 'ACCEPTED', label: t('app:actions.accepted') },
                { value: 'CONFIRMED', label: t('app:actions.confirmed') },
                { value: 'DECLINED', label: t('app:actions.declined') }
              ]}
            />

            <Divider
              orientation={isSmallDevice ? 'horizontal' : 'vertical'}
              flexItem
            />

            <FiltersSettingsSection
              title={t('calendar:filters.labels.accounts')}
              name="resourceIds"
              options={getResources()}
            />
          </Stack>
        </Box>
      </form>
    </FormProvider>
  )
}
