import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import { FiltersActive } from './active/FiltersActive'
import { FiltersSettings } from './settings/FiltersSettings'

interface FiltersProps {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
}

export function Filters({ open, anchorEl, handleClose }: FiltersProps) {
  const { t } = useTranslation(['calendar'])

  return (
    <>
      <FiltersActive />

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack
          direction="row"
          px={2}
          mb={-1.25}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">{t('calendar:filters.title')}</Typography>

          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        </Stack>

        <FiltersSettings />
      </Menu>
    </>
  )
}
