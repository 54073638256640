import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { useEvents } from 'sections/calendar/EventsContext'
import { useDateFns } from 'utils/date'
import { useNotifications } from 'utils/notifications'

interface MonthSelectorDialogItemProps {
  startDate: Date
  isActive: boolean
  onClose: () => void
}

export function MonthSelectorDialogItem({
  startDate,
  isActive,
  onClose
}: MonthSelectorDialogItemProps) {
  const dateFns = useDateFns()
  const label = dateFns.format(startDate, 'month')

  const notifications = useNotifications()

  const { setMonth } = useEvents()

  const notificationsCount = notifications.processes.filter(
    process =>
      startOfMonth(process.start).setHours(0, 0, 0, 0) === startDate.getTime()
  ).length

  const handleMonthClick = () => {
    setMonth(startDate.getTime(), endOfMonth(startDate).getTime())
    onClose()
  }

  return (
    <Box sx={{ cursor: 'pointer', flexBasis: '25%' }}>
      <Badge
        badgeContent={notificationsCount}
        color="error"
        onClick={handleMonthClick}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ paddingLeft: 0.75 }}
      >
        <Typography variant="body2" fontWeight={isActive ? 'bold' : 'normal'}>
          {label.charAt(0).toUpperCase() + label.slice(1)}
        </Typography>
      </Badge>
    </Box>
  )
}
