import { PageSession } from 'components/PageSession'
import { LayoutApp } from 'components/layout/app/LayoutApp'
import { SeoTitle } from 'components/seo/SeoTitle'
import { DEFAULT_REVALIDATE_TIME } from 'constants/hydrate'
import { LINKS } from 'constants/links'
import { FALLBACK_LOCALE } from 'constants/locale'
import { GetServerSideProps } from 'next'
import { useSession } from 'next-auth/react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Calendar } from 'sections/calendar/Calendar'
import { EventsProvider } from 'sections/calendar/EventsContext'
import { FiltersProvider } from 'sections/calendar/filters/FiltersContext'
import { useDateFns } from 'utils/date'
import { localStorageClient, sessionStorageClient } from 'utils/dom'
import { usePageTranslation } from 'utils/intl'
import { useNotifications } from 'utils/notifications'
import { TimeZoneProvider } from 'utils/timeZone'

const now = new Date()

function CalendarPage() {
  const router = useRouter()
  const dateFns = useDateFns()

  const t = usePageTranslation(['common', 'app', 'calendar', 'personalEvent'])

  const session = useSession()

  const { resources, isLoading } = useNotifications()

  useEffect(() => {
    const displayedAccounts = localStorageClient.getItem('displayedAccounts')
    if (
      !displayedAccounts &&
      !isLoading &&
      session.status === 'authenticated'
    ) {
      if (resources.some(r => r.email === session.data?.user?.email)) {
        localStorageClient.setItem('displayedAccounts', JSON.stringify(true))
        router.push(LINKS.appAccounts)
      } else {
        localStorageClient.setItem('displayedAccounts', JSON.stringify(true))
      }
    }
  }, [isLoading, resources, router, session.data?.user?.email, session.status])

  useEffect(() => {
    const invitationParams = sessionStorageClient.getItem('calendarInvitation')

    if (invitationParams) {
      const invitationParamsObj = JSON.parse(invitationParams)

      if (
        session.status === 'authenticated' &&
        invitationParamsObj.email !== session.data?.user?.email
      ) {
        sessionStorageClient.removeItem('calendarInvitation')
      } else if (
        invitationParamsObj.processDate &&
        invitationParamsObj.email === session.data?.user?.email
      ) {
        sessionStorageClient.removeItem('calendarInvitation')
        const date = new Date(invitationParamsObj.processDate)
        const params = {
          start: dateFns.startOfMonth(date).getTime(),
          end: dateFns.endOfMonth(date).getTime(),
          view: 'agenda'
        }
        localStorageClient.setItem('calendarParams', JSON.stringify(params))
        router.push({ query: params }, undefined, {
          shallow: true,
          scroll: true
        })
      }
    } else if (!router.query?.start || !router.query?.end) {
      const savedParams = localStorageClient.getItem('calendarParams')

      const defaultParams = {
        start: dateFns.startOfMonth(now).getTime(),
        end: dateFns.endOfMonth(now).getTime(),
        view: 'agenda'
      }

      if (!savedParams) {
        localStorageClient.setItem(
          'calendarParams',
          JSON.stringify(defaultParams)
        )

        router.push({ query: defaultParams }, undefined, {
          shallow: true,
          scroll: true
        })
      } else {
        router.push({ query: JSON.parse(savedParams) }, undefined, {
          shallow: true,
          scroll: true
        })
      }
    }
  }, [dateFns, router, session.data?.user?.email, session.status])

  return (
    <PageSession allowedGroup="user">
      <>
        <SeoTitle title={t('calendar:title')} />

        <LayoutApp>
          <TimeZoneProvider>
            <FiltersProvider>
              <EventsProvider>
                <Calendar />
              </EventsProvider>
            </FiltersProvider>
          </TimeZoneProvider>
        </LayoutApp>
      </>
    </PageSession>
  )
}

export const getStaticProps: GetServerSideProps = async context => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? FALLBACK_LOCALE, [
        'common',
        'app',
        'calendar',
        'personalEvent'
      ]))
    },
    revalidate: DEFAULT_REVALIDATE_TIME
  }
}

export default CalendarPage
