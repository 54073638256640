import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/system/Box'
import Stack from '@mui/system/Stack'
import { ModalBase } from 'components/modals/ModalBase'
import { DEFAULT_FORM_VALIDATION_MODE } from 'constants/forms'
import { useTranslation } from 'next-i18next'
import { SyntheticEvent, memo, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Frequency } from 'rrule'
import {
  RecurrenceFormValues,
  a11yProps,
  translateWeekDay,
  useRecurrenceDates,
  useRecurrenceFormValidationSchema
} from './Recurrence.utils'
import { RecurrenceDates } from './RecurrenceDates'
import { RecurrenceSectionEnd } from './sections/RecurrenceSectionEnd'
import { RecurrenceSectionRepeat } from './sections/repeat/RecurrenceSectionRepeat'
import { RecurrenceTabPanel } from './tabPanels/RecurrenceTabPanel'
import { RecurrenceTabPanelIrregularly } from './tabPanels/irregularly/RecurrenceTabPanelIrregularly'
import { RecurrenceTabPanelWeekly } from './tabPanels/weekly/RecurrenceTabPanelWeekly'

interface RecurrenceProps {
  isOpened: boolean
  startDate: Date
  onClose: () => void
  onSave: (dates: Date[]) => void
  onDelete: () => void
}

export function RecurrenceComponent({
  isOpened,
  startDate,
  onClose,
  onSave,
  onDelete
}: RecurrenceProps) {
  const { t } = useTranslation(['app', 'personalEvent'])

  const { schema, errorMap } = useRecurrenceFormValidationSchema()
  const form = useForm<RecurrenceFormValues>({
    resolver: zodResolver(schema, { errorMap }),
    mode: DEFAULT_FORM_VALIDATION_MODE,
    defaultValues: {
      repeatType: 'monthDay',
      count: 2,
      weekDays: [translateWeekDay(startDate.getDay())],
      months: [],
      irregular: []
    }
  })

  const [activeTab, setActiveTab] = useState(0)

  const { dates, changeFrequency, changeStartDate } = useRecurrenceDates(
    startDate,
    form
  )
  const lastDate = dates[dates.length - 1]

  useEffect(() => {
    changeStartDate(startDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      changeFrequency(Frequency.WEEKLY)
    } else if (newValue === 1) {
      changeFrequency(Frequency.MONTHLY)
    } else if (newValue === 2) {
      changeFrequency('IRREGULARLY')
    }

    setActiveTab(newValue)
  }

  return (
    <ModalBase
      isOpened={isOpened}
      onClose={onClose}
      variant="x-large"
      title={t('personalEvent:recurrenceDetail.title')}
      sx={{ pt: 0 }}
    >
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab
              label={t('personalEvent:recurrenceDetail.tabs.weekly')}
              {...a11yProps(0)}
            />
            <Tab
              label={t('personalEvent:recurrenceDetail.tabs.monthly')}
              {...a11yProps(1)}
            />
            <Tab
              label={t('personalEvent:recurrenceDetail.tabs.irregularly')}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>

        <FormProvider {...form}>
          <RecurrenceTabPanel activeTab={activeTab} index={0}>
            <RecurrenceSectionEnd lastDate={lastDate} />
            <RecurrenceTabPanelWeekly />
          </RecurrenceTabPanel>

          <RecurrenceTabPanel activeTab={activeTab} index={1}>
            <RecurrenceSectionEnd lastDate={lastDate} />
            <RecurrenceSectionRepeat startDate={startDate} />
          </RecurrenceTabPanel>

          <RecurrenceTabPanel activeTab={activeTab} index={2}>
            <RecurrenceTabPanelIrregularly startDate={startDate} />
          </RecurrenceTabPanel>
        </FormProvider>

        <RecurrenceDates dates={dates} />

        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={() => {
              if (dates.length === 1) {
                onDelete()
              } else {
                onSave(dates)
              }
              onClose()
            }}
          >
            {t('app:actions.save')}
          </Button>

          <Button
            variant="contained"
            color="error"
            size="small"
            fullWidth
            onClick={onDelete}
            sx={{ color: 'white' }}
          >
            {t('personalEvent:recurrenceDetail.buttonDelete')}
          </Button>
          <Button variant="outlined" size="small" fullWidth onClick={onClose}>
            {t('app:actions.cancel')}
          </Button>
        </Stack>
      </Box>
    </ModalBase>
  )
}

export const Recurrence = memo(RecurrenceComponent)
