import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCalendarActionsMutation } from 'api/dotu/calendar/calendarActions'
import { ProcessEvent } from 'api/dotu/calendar/calendarList.utils'
import { isBefore } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useTranslation } from 'next-i18next'
import { useEvents } from 'sections/calendar/EventsContext'
import { CastStatus } from 'utils/calendarStatus'
import { DEFAULT_TIMEZONE } from 'utils/timeZone'

interface AgendaRowActionsProcessDefaultProps {
  event: ProcessEvent
  castStatus: CastStatus
  onUpdate: (status: number) => void
}

export function AgendaRowActionsProcessDefault({
  event,
  castStatus,
  onUpdate
}: AgendaRowActionsProcessDefaultProps) {
  const { t } = useTranslation(['app'])

  const start = utcToZonedTime(event.start, DEFAULT_TIMEZONE.name)

  const { range } = useEvents()

  const { accept, decline, isLoadingAccept, isLoadingDecline } =
    useCalendarActionsMutation(
      range.start ? new Date(range.start) : undefined,
      range.end ? new Date(range.end) : undefined,
      onUpdate
    )

  const getCastIds = () => {
    if (event.tourProcesses) {
      return event.tourProcesses
        .filter(process => process.cast.id)
        .map(process => process.cast.id as number)
    } else if (event.cast.id) {
      return [event.cast.id]
    }
    return []
  }

  const isDisabled = isBefore(start, new Date())

  return (
    <Stack direction="row" gap={1} justifyContent="center">
      <Button
        disabled={isLoadingAccept || isLoadingDecline || isDisabled}
        size="small"
        sx={{
          color: 'castStatus.default.accept',
          borderColor: 'castStatus.default.accept',
          '&:hover': {
            borderColor: 'castStatus.default.accept'
          }
        }}
        onClick={() => {
          if (castStatus !== 'ACCEPTED' && event.cast.id) {
            accept({ data: { castIds: getCastIds() } })
          }
        }}
        variant={castStatus === 'ACCEPTED' ? 'contained' : 'outlined'}
      >
        {isLoadingAccept ? (
          <CircularProgress
            size={16}
            sx={{ color: 'castStatus.default.accept' }}
          />
        ) : (
          <Stack direction="row" gap={1} alignItems="center">
            <FontAwesomeIcon icon={faCheck} />
            <Typography variant="body2" fontWeight="medium" color="inherit">
              {t('app:actions.accept')}
            </Typography>
          </Stack>
        )}
      </Button>

      <Button
        disabled={isLoadingAccept || isLoadingDecline || isDisabled}
        size="small"
        onClick={() => {
          if (castStatus !== 'DECLINED' && event.cast.id) {
            decline({ data: { castIds: getCastIds() } })
          }
        }}
        variant={castStatus === 'DECLINED' ? 'contained' : 'outlined'}
        sx={{
          borderColor: 'castStatus.default.decline',
          '&:hover': {
            borderColor: 'castStatus.default.decline'
          }
        }}
      >
        {isLoadingDecline ? (
          <CircularProgress
            size={16}
            sx={{ color: 'castStatus.default.decline' }}
          />
        ) : (
          <Stack direction="row" gap={1} alignItems="center">
            <FontAwesomeIcon icon={faXmark} />
            <Typography variant="body2" fontWeight="medium" color="inherit">
              {t('app:actions.decline')}
            </Typography>
          </Stack>
        )}
      </Button>
    </Stack>
  )
}
