import { useCalendarFastPrefetch } from 'api/dotu/calendar/calendarFastPrefetch'
import { useCalendarListQuery } from 'api/dotu/calendar/calendarList'
import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import addMonths from 'date-fns/addMonths'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { useRouter } from 'next/router'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDateFns } from 'utils/date'
import { localStorageClient } from 'utils/dom'
import { getFilteredEvents } from './filters/Filters.utils'
import { useFilters } from './filters/FiltersContext'

interface EventsContextValue {
  events?: CalendarEvent[]
  range: {
    start?: string
    end?: string
  }
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  setPreviousMonth: () => void
  setCurrentMonth: () => void
  setNextMonth: () => void
  updateEvents: Dispatch<SetStateAction<CalendarEvent[]>>
  setMonth: (start: number, end: number) => void
  refetch: () => void
}

const EventsContext = createContext<EventsContextValue>({
  events: [],
  range: {
    start: '',
    end: ''
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  setPreviousMonth: () => undefined,
  setCurrentMonth: () => undefined,
  setNextMonth: () => undefined,
  updateEvents: () => undefined,
  setMonth: () => undefined,
  refetch: () => undefined
})

const currentDate = new Date()

export function EventsProvider({ children }: PropsWithChildren) {
  const { query, push } = useRouter()

  const activeView = (query.view as string) || 'agenda'

  const dateFns = useDateFns()

  const prefetchPrev = useCalendarFastPrefetch()
  const prefetchNext = useCalendarFastPrefetch()

  const [datesRange, setDatesRange] = useState({
    start:
      query.start && !Array.isArray(query.start)
        ? new Date(parseInt(query.start)).toISOString()
        : undefined,
    end:
      query.end && !Array.isArray(query.end)
        ? new Date(parseInt(query.end)).toISOString()
        : undefined
  })

  const { data, isLoading, isSuccess, isError, refetch } = useCalendarListQuery(
    datesRange?.start ? new Date(datesRange.start) : undefined,
    datesRange?.end ? new Date(datesRange.end) : undefined,
    undefined,
    undefined,
    undefined,
    {
      onSuccess: () => {
        if (datesRange.start && datesRange.end) {
          prefetchPrev(
            new Date(
              dateFns
                .addMonths(new Date(datesRange.start), -1)
                .setHours(0, 0, 0, 0)
            ),
            dateFns.endOfMonth(
              new Date(
                dateFns
                  .addMonths(new Date(datesRange.end), -1)
                  .setHours(0, 0, 0, 0)
              )
            )
          )

          prefetchNext(
            new Date(
              dateFns
                .addMonths(new Date(datesRange.start), 1)
                .setHours(0, 0, 0, 0)
            ),
            dateFns.endOfMonth(
              new Date(
                dateFns
                  .addMonths(new Date(datesRange.end), 1)
                  .setHours(0, 0, 0, 0)
              )
            )
          )
        }
      }
    }
  )

  const [loadedEvents, setLoadedEvents] = useState<CalendarEvent[]>([])

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(loadedEvents)) {
      setLoadedEvents(data)
    }
  }, [data, loadedEvents])

  useEffect(() => {
    if (
      query.start &&
      query.end &&
      !Array.isArray(query.start) &&
      !Array.isArray(query.end) &&
      ((!datesRange.start && !datesRange.end) ||
        (datesRange.start &&
          parseInt(query.start) !== new Date(datesRange.start).getTime() &&
          datesRange.end &&
          parseInt(query.end) !== new Date(datesRange.end).getTime()))
    ) {
      const start = new Date(parseInt(query.start))
      const end = new Date(parseInt(query.end))

      if (
        datesRange.start !== start.toISOString() &&
        datesRange.end !== end.toISOString()
      ) {
        setDatesRange({
          start: start.toISOString(),
          end: end.toISOString()
        })
      }
    }
  }, [dateFns, datesRange.end, datesRange.start, query])

  const { activeFilters } = useFilters()

  const filteredEvents = useMemo(() => {
    if (activeFilters) {
      return getFilteredEvents(loadedEvents, activeFilters)
    } else {
      return loadedEvents
    }
  }, [activeFilters, loadedEvents])

  const setCurrentMonth = () => {
    changeRange(
      startOfMonth(currentDate).setHours(0, 0, 0, 0),
      endOfMonth(currentDate).setHours(0, 0, 0, 0)
    )
  }

  const setPreviousMonth = () => {
    if (!datesRange.start || !datesRange.end) return

    changeRange(
      startOfMonth(addMonths(new Date(datesRange.start), -1)).getTime(),
      endOfMonth(addMonths(new Date(datesRange.end), -1)).getTime()
    )
  }

  const setNextMonth = () => {
    if (!datesRange.start || !datesRange.end) return

    changeRange(
      startOfMonth(addMonths(new Date(datesRange.start), 1)).getTime(),
      endOfMonth(addMonths(new Date(datesRange.end), 1)).getTime()
    )
  }

  const changeRange = (start: number, end: number) => {
    const range = { start, end }

    localStorageClient.setItem(
      'calendarParams',
      JSON.stringify({
        ...range,
        view: activeView
      })
    )
    push(
      {
        query: {
          ...range,
          view: activeView
        }
      },
      undefined,
      {
        shallow: true,
        scroll: true
      }
    )
  }

  return (
    <EventsContext.Provider
      value={{
        events: filteredEvents,
        range: datesRange,
        isLoading,
        isSuccess,
        isError,
        refetch,
        updateEvents: setLoadedEvents,
        setCurrentMonth,
        setPreviousMonth,
        setNextMonth,
        setMonth: changeRange
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}

export function useEvents() {
  const context = useContext(EventsContext)
  if (!context) {
    throw new Error('useEvents must be used within a EventsContext')
  }
  return context
}
