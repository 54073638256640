import { PersonalEventDetailModalDateModifiers } from './PersonalEventDetailModalDateModifiers'
import { PersonalEventDetailModalDateRange } from './PersonalEventDetailModalDateRange'

interface PersonalEventDetailModalDateProps {
  isRecurrenceEnabled: boolean
}

export function PersonalEventDetailModalDate({
  isRecurrenceEnabled
}: PersonalEventDetailModalDateProps) {
  return (
    <>
      <PersonalEventDetailModalDateRange />
      <PersonalEventDetailModalDateModifiers
        isRecurrenceEnabled={isRecurrenceEnabled}
      />
    </>
  )
}
