import { useQueryClient } from '@tanstack/react-query'
import {
  getApiProcessCalendarFast,
  getGetApiProcessCalendarFastQueryKey
} from 'api/generated/dotu/process'
import { addDays } from 'date-fns'

export const useCalendarFastPrefetch = () => {
  const queryClient = useQueryClient()

  return async (startDate: Date, endDate: Date) => {
    const start = addDays(startDate, -1)
    const end = addDays(endDate, 1)

    await queryClient.prefetchQuery({
      queryKey: getGetApiProcessCalendarFastQueryKey({
        from: start.toISOString(),
        to: end.toISOString()
      }),
      queryFn: async () => {
        return await getApiProcessCalendarFast({
          from: start.toISOString(),
          to: end.toISOString()
        })
      }
    })
  }
}
