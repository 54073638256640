import { useQueryClient } from '@tanstack/react-query'
import {
  CalendarEventResource,
  PersonalEvent
} from 'api/dotu/calendar/calendarList.utils'
import {
  CalendarFastResponseDTO,
  SessionResourceDTO
} from 'api/generated/dotu/dotu.schemas'
import { getGetApiProcessCalendarFastQueryKey } from 'api/generated/dotu/process'
import { useDateFns } from 'utils/date'

export const useRefreshRecurringCache = () => {
  const queryClient = useQueryClient()

  return (recurringId: string, newResources: SessionResourceDTO[]) => {
    const queryKey = getGetApiProcessCalendarFastQueryKey({
      recurringId
    })

    const resourcesIds = newResources.map(r => r.id)

    queryClient.setQueryData(queryKey, (old?: CalendarFastResponseDTO) => {
      const oldPersonalEvents = old?.personalEvents ?? []

      const newPersonalEvents = oldPersonalEvents.filter(
        p => p.resourceId && resourcesIds.includes(p.resourceId)
      )

      return {
        ...old,
        personalEvents: newPersonalEvents
      }
    })
  }
}

export const useRefreshCache = () => {
  const dateFns = useDateFns()
  const queryClient = useQueryClient()

  const updateSingleEventCache = (
    process: PersonalEvent,
    processInstance?: CalendarEventResource
  ) => {
    const queryKey = getGetApiProcessCalendarFastQueryKey({
      from: new Date(
        dateFns.addDays(dateFns.startOfMonth(process.start), -1)
      ).toISOString(),
      to: new Date(
        dateFns.addDays(dateFns.endOfMonth(process.start), 1)
      ).toISOString()
    })

    const previousData = queryClient.getQueryData(
      queryKey
    ) as CalendarFastResponseDTO

    const processId = processInstance?.id ?? process.id

    const cacheProcess = previousData?.personalEvents?.find(
      p => p.id === processId
    )

    if (cacheProcess) {
      queryClient.setQueryData(queryKey, (old?: CalendarFastResponseDTO) => {
        const oldPersonalEvents = old?.personalEvents ?? []

        const newPersonalEvents = oldPersonalEvents.filter(
          p => p.id !== processId
        )

        return {
          ...old,
          personalEvents: newPersonalEvents
        }
      })
    } else {
      queryClient.invalidateQueries({
        queryKey
      })
    }
  }

  const updateMultipleEventsCache = (process: PersonalEvent) => {
    queryClient.invalidateQueries({
      queryKey: getGetApiProcessCalendarFastQueryKey()
    })
  }

  return (
    process: PersonalEvent,
    type: 'single' | 'multiple',
    onSuccess: () => void,
    processInstance?: CalendarEventResource
  ) => {
    if (type === 'single') {
      updateSingleEventCache(process, processInstance)
    } else {
      updateMultipleEventsCache(process)
    }

    onSuccess()
  }
}
