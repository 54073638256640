import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import { PersonalEvent } from 'api/dotu/calendar/calendarList.utils'
import {
  CalendarProcessDTO,
  SessionResourceDTO
} from 'api/generated/dotu/dotu.schemas'
import {
  useDeleteApiProcessCalendarId,
  useDeleteApiProcessRecurring,
  useGetApiProcessCalendarFast
} from 'api/generated/dotu/process'
import { useGetApiSession } from 'api/generated/dotu/session'
import { ModalBase } from 'components/modals/ModalBase'
import {
  useRefreshCache,
  useRefreshRecurringCache
} from './PersonalEventDeleteModal.utils'
import { PersonalEventDeleteAccounts } from './accounts/PersonalEventDeleteAccounts'
import { PersonalEventDeleteRecurrence } from './recurrence/PersonalEventDeleteRecurrence'

interface PersonalEventDeleteModalProps {
  process: PersonalEvent
  isOpened: boolean
  onClose: () => void
}

export const PersonalEventDeleteModal: FC<PersonalEventDeleteModalProps> = ({
  process,
  isOpened,
  onClose
}) => {
  const { mutate: deleteSingle, isLoading: isLoadingDeleteSingle } =
    useDeleteApiProcessCalendarId()
  const { mutate: deleteAll, isLoading: isLoadingDeleteMultiple } =
    useDeleteApiProcessRecurring()
  const { data: sessionData } = useGetApiSession()

  const [deleteOptions, setDeleteOptions] = useState<{
    type: 'single' | 'multiple'
    resources: SessionResourceDTO[]
  }>({
    type: 'single',
    resources: process.resources
  })
  const refreshCache = useRefreshCache()
  const refreshCacheEvents = useRefreshRecurringCache()

  const { t } = useTranslation(['app', 'personalEvent'])

  const isRecurring = Boolean(process.recurringId)

  const { data: recurringEvents, isLoading: isLoadingRecurringEvents } =
    useGetApiProcessCalendarFast(
      {
        recurringId: process.recurringId ?? ''
      },
      { query: { enabled: isRecurring } }
    )

  useEffect(() => {
    setDeleteOptions({
      type: 'single',
      resources: process.resources
    })
  }, [isOpened, process.resources])

  useEffect(() => {
    if (
      deleteOptions.type === 'single' &&
      deleteOptions.resources.length > process.resources.length
    ) {
      const singleEventIds = process.resources.map(r => r.id)

      setDeleteOptions({
        type: 'single',
        resources: process.resources.filter(r => singleEventIds.includes(r.id))
      })
    }
  }, [deleteOptions, process.resources])

  const isLoading =
    isLoadingDeleteMultiple ||
    isLoadingDeleteSingle ||
    (isLoadingRecurringEvents && isRecurring)

  const getAllPossibleResources = (): SessionResourceDTO[] => {
    if (!recurringEvents) return []

    const resourcesIds: number[] = []

    recurringEvents.personalEvents?.forEach(p => {
      if (p.resourceId && !resourcesIds.includes(p.resourceId)) {
        resourcesIds.push(p.resourceId)
      }
    })

    return resourcesIds
      .map(id => sessionData?.resources?.find(r => r.id === id))
      .filter(Boolean) as SessionResourceDTO[]
  }

  const deleteProcess = () => {
    if (deleteOptions.type === 'single') {
      if (process.resources.length === deleteOptions.resources.length) {
        // all participants selected => delete single day personal event
        process.resources.forEach(event => {
          deleteSingle(
            { id: event.processId as number },
            {
              onSuccess: () => refreshCache(process, 'single', onClose, event)
            }
          )
        })
      } else {
        // some of participants selected => remove selected participants from single day event
        const selectedResources = deleteOptions.resources.map(r => r.id)
        process.resources
          .filter(r => selectedResources.includes(r.id))
          .forEach(event => {
            deleteSingle(
              { id: event.processId as number },
              {
                onSuccess: () => refreshCache(process, 'single', onClose, event)
              }
            )
          })
      }
    } else if (deleteOptions.type === 'multiple') {
      if (deleteOptions.resources.length === getAllPossibleResources().length) {
        // all participants selected => delete all recurring days of personal event
        deleteAll(
          {
            params: {
              recurringId: process.recurringId ?? undefined
            }
          },
          {
            onSuccess: () => refreshCache(process, 'multiple', onClose)
          }
        )
      } else if (recurringEvents) {
        // some of participants selected => remove selected participants from all instances of recurring event
        const selectedResources = deleteOptions.resources.map(r => r.id)
        recurringEvents.personalEvents
          ?.filter(
            (p: CalendarProcessDTO, index: number) =>
              p.resourceId &&
              selectedResources.includes(p.resourceId) &&
              p.recurringId === process.recurringId &&
              index ===
                recurringEvents.personalEvents?.findIndex(e => e.id === p.id)
          )
          .forEach(p => {
            deleteSingle(
              { id: p.id as number },
              {
                onSuccess: () => {
                  refreshCache(process, 'single', onClose, p)
                  refreshCacheEvents(
                    process.recurringId ?? '',
                    getAllPossibleResources().filter(
                      r => !selectedResources.includes(r.id)
                    )
                  )
                }
              }
            )
          })
      }
    }
  }

  if (!process.id) {
    return null
  }

  return (
    <ModalBase
      title={t('personalEvent:delete.title')}
      isOpened={isOpened}
      onClose={onClose}
      variant="small"
    >
      <Box>
        <Typography marginBottom={2} textAlign="center">
          {isRecurring
            ? t('personalEvent:delete.description.multiple')
            : t('personalEvent:delete.description.single')}
        </Typography>

        <Stack gap={2} alignItems="center">
          {isRecurring && (
            <PersonalEventDeleteRecurrence
              startDate={process.start}
              activeType={deleteOptions.type}
              setType={(type: 'single' | 'multiple') =>
                setDeleteOptions({ ...deleteOptions, type })
              }
            />
          )}

          <PersonalEventDeleteAccounts
            resources={process.resources}
            allResources={getAllPossibleResources()}
            displayAll={deleteOptions.type === 'multiple'}
            activeResources={deleteOptions.resources}
            setResources={(resources: SessionResourceDTO[]) =>
              setDeleteOptions({ ...deleteOptions, resources })
            }
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between" mt={4} gap={4}>
          <Button
            variant="contained"
            color="error"
            size="small"
            fullWidth
            onClick={deleteProcess}
            disabled={isLoading || deleteOptions.resources.length < 1}
          >
            {t('personalEvent:delete.button')}
          </Button>

          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={onClose}
            disabled={isLoading}
          >
            {t('app:actions.cancel')}
          </Button>
        </Stack>
      </Box>
    </ModalBase>
  )
}
