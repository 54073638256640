import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { getTimeZones } from '@vvo/tzdb'
import { PersonalEvent } from 'api/dotu/calendar/calendarList.utils'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { Input } from 'components/form/Input'
import { ModalBase } from 'components/modals/ModalBase'
import { DEFAULT_FORM_VALIDATION_MODE } from 'constants/forms'
import { startOfHour } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useEvents } from 'sections/calendar/EventsContext'
import { useDateFns } from 'utils/date'
import { DEFAULT_TIMEZONE, useEventTimeZone } from 'utils/timeZone'
import {
  PersonalEventFormValues,
  PersonalEventFormValuesValidated,
  useAllPersons,
  usePersonalEventFormValidationSchema,
  usePersonalEventSubmit
} from './PersonalEventDetailModal.utils'
import { PersonalEventDetailModalDate } from './date/PersonalEventDetailModalDate'
import { PersonalEventDetailModalDateTimeZone } from './date/PersonalEventDetailModalDateTimeZone'
import { PersonalEventDetailModalPerson } from './person/PersonalEventDetailModalPerson'

interface PersonalEventDetailModalProps {
  isOpened: boolean
  onClose: () => void
  personalEvent?: PersonalEvent
}

export function PersonalEventDetailModal({
  isOpened,
  onClose,
  personalEvent
}: PersonalEventDetailModalProps) {
  const { t } = useTranslation(['app', 'personalEvent'])

  const { submit, isLoading } = usePersonalEventSubmit(onClose)

  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  const { data } = useGetApiStartCalendar()

  const { range } = useEvents()

  const getAllPersons = useAllPersons()

  const dateFns = useDateFns()

  useEffect(() => {
    if (isOpened) {
      const now = new Date()
      const isCurrentMonth = range.start
        ? dateFns.isSameMonth(now, new Date(range.start))
        : false

      const firstMonthDay = range.start
        ? new Date(new Date(range.start).setHours(8, 0, 0, 0))
        : now

      const newDate = isCurrentMonth ? now : firstMonthDay

      // set new date on open
      setCurrentDate(newDate)
      form.reset({
        ...DEFAULT_VALUES,
        startDate: personalEvent?.start
          ? utcToZonedTime(personalEvent.start, defaultTimeZone.name)
          : getNearestFullHour(newDate),
        endDate: personalEvent?.end
          ? utcToZonedTime(personalEvent.end, defaultTimeZone.name)
          : dateFns.addHours(getNearestFullHour(newDate), 1)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  const isFullDay = () => {
    if (
      personalEvent?.start?.getHours() === 0 &&
      personalEvent?.start?.getMinutes() === 0 &&
      personalEvent?.start?.getSeconds() === 0 &&
      personalEvent?.end?.getHours() === 23 &&
      personalEvent?.end?.getMinutes() === 59 &&
      personalEvent?.end?.getSeconds() === 59
    ) {
      return true
    } else {
      return false
    }
  }

  const getDefaultPerson = () => {
    const allPersons = getAllPersons()

    if (personalEvent?.resources) {
      return {
        email: personalEvent.resources[0].email ?? undefined,
        companyIds:
          allPersons.find(
            person => person.email === personalEvent.resources[0].email
          )?.companyIds ?? []
      }
    } else if (allPersons.length === 1) {
      return {
        email: allPersons[0].email ?? undefined,
        companyIds: allPersons[0].companyIds || []
      }
    }
    return undefined
  }

  const getDefaultTheaters = () => {
    const allPersons = getAllPersons()

    if (personalEvent?.resources) {
      return personalEvent.resources.map(resource => resource.company)
    } else if (allPersons.length === 1) {
      return allPersons[0].companyIds.map(companyId =>
        data?.companies?.find(company => company.id === companyId)
      )
    }

    return undefined
  }

  const getNearestFullHour = (date: Date) => {
    const fullHour = startOfHour(date)
    if (dateFns.isAfter(date, fullHour)) {
      return dateFns.addHours(fullHour, 1)
    } else {
      return fullHour
    }
  }

  const getEventTimeZone = useEventTimeZone()

  const defaultTimeZone =
    getTimeZones().find(tz => tz.name === getEventTimeZone(personalEvent)) ??
    DEFAULT_TIMEZONE

  const DEFAULT_VALUES = {
    name: personalEvent?.title ?? '',
    note: personalEvent?.note ?? '',
    startDate: personalEvent?.start
      ? utcToZonedTime(personalEvent.start, defaultTimeZone.name)
      : getNearestFullHour(currentDate),
    endDate: personalEvent?.end
      ? utcToZonedTime(personalEvent.end, defaultTimeZone.name)
      : dateFns.addHours(getNearestFullHour(currentDate), 1),
    isFullDay: isFullDay(),
    person: getDefaultPerson(),
    theaters: getDefaultTheaters(),
    timeZone: defaultTimeZone
  }

  const { schema, errorMap } = usePersonalEventFormValidationSchema()
  const form = useForm<PersonalEventFormValues>({
    resolver: zodResolver(schema, { errorMap }),
    mode: DEFAULT_FORM_VALIDATION_MODE,
    defaultValues: DEFAULT_VALUES
  })

  const onSubmit = (values: PersonalEventFormValues) => {
    submit(values as PersonalEventFormValuesValidated, personalEvent)
  }

  const getSubmitButtonContent = () => {
    if (isLoading) {
      return <CircularProgress size={16} />
    } else if (personalEvent) {
      return t('personalEvent:detail.edit.button')
    } else {
      return t('personalEvent:detail.create.button')
    }
  }

  return (
    <ModalBase
      variant="large"
      isOpened={isOpened}
      onClose={onClose}
      title={t(
        personalEvent
          ? 'personalEvent:detail.edit.title'
          : 'personalEvent:detail.create.title'
      )}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2}>
            <Controller
              name="name"
              control={form.control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={t('personalEvent:detail.fields.eventName')}
                  required
                />
              )}
            />

            <PersonalEventDetailModalPerson />

            <PersonalEventDetailModalDate
              isRecurrenceEnabled={!personalEvent}
            />

            <Controller
              name="note"
              control={form.control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={t('personalEvent:detail.fields.note')}
                  rows={2}
                  multiline
                />
              )}
            />

            <PersonalEventDetailModalDateTimeZone />

            <Stack gap={1.5} direction="row">
              <Button
                size="large"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!form.formState.isValid || isLoading}
              >
                {getSubmitButtonContent()}
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={onClose}
                fullWidth
              >
                {t('app:actions.cancel')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </ModalBase>
  )
}
