import { useRouter } from 'next/router'

export const useOrdinalNumber = () => {
  const { locale } = useRouter()

  return (n: number) => {
    if (locale !== 'en') {
      return n + '.'
    }

    let suffix = ''
    if (n > 0) {
      if ((n > 3 && n < 21) || n % 10 > 3) {
        suffix = 'th'
      } else if (n % 10 === 1) {
        suffix = 'st'
      } else if (n % 10 === 2) {
        suffix = 'nd'
      } else {
        suffix = 'rd'
      }
    }
    return n + suffix
  }
}
