import { useCalendarListQuery } from 'api/dotu/calendar/calendarList'
import {
  ProcessEvent,
  ProcessEventSingle
} from 'api/dotu/calendar/calendarList.utils'
import { useEffect, useState } from 'react'
import { getCastStatus } from 'utils/calendarStatus'
import { AgendaRowActionsProcessDefault } from './AgendaRowActionsProcessDefault'
import { AgendaRowActionsProcessFilled } from './AgendaRowActionsProcessFilled'

interface AgendaRowActionsProcessProps {
  event: ProcessEvent
  tourEvent?: ProcessEventSingle
}

export function AgendaRowActionsProcess({
  event,
  tourEvent
}: AgendaRowActionsProcessProps) {
  const status = tourEvent?.castStatus ?? event.cast.status

  const [castStatus, setCastStatus] = useState(
    status ? getCastStatus(status) : 'HIDDEN'
  )
  const { data } = useCalendarListQuery()

  useEffect(() => {
    if (data) {
      const process = data.find(item => item.id === event.id)

      if (process?.castStatus) {
        const status = getCastStatus(process.castStatus)
        if (status !== castStatus) {
          setCastStatus(getCastStatus(process.castStatus))
        }
      }
    }
  }, [castStatus, data, event.id])

  if (castStatus === 'PENDING') {
    return (
      <AgendaRowActionsProcessDefault
        event={event}
        castStatus={castStatus}
        onUpdate={(status: number) => {
          setCastStatus(getCastStatus(status))
        }}
      />
    )
  } else {
    return <AgendaRowActionsProcessFilled castStatus={castStatus} />
  }
}
