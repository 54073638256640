import { useQueryClient } from '@tanstack/react-query'
import { PersonalEvent } from 'api/dotu/calendar/calendarList.utils'
import { CalendarProcessDTOCreate } from 'api/generated/dotu/dotu.schemas'
import {
  getGetApiProcessCalendarFastQueryKey,
  useDeleteApiProcessCalendarId,
  useDeleteApiProcessRecurring,
  usePostApiProcessCalendar
} from 'api/generated/dotu/process'
import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import { nanoid } from 'nanoid'
import { useTranslation } from 'next-i18next'
import { toast } from 'react-hot-toast'
import { useDateFns } from 'utils/date'
import { isDate } from 'utils/types'
import { useDefaultZodErrorMap } from 'utils/validations'
import {
  any,
  boolean,
  date,
  number,
  object,
  string,
  infer as zodInfer
} from 'zod'

export function usePersonalEventFormValidationSchema() {
  const { t } = useTranslation(['common', 'personalEvent'])

  const schema = object({
    name: string().min(3, t('common:validationError.required')),
    startDate: date().superRefine((value, context) => {
      if (!isDate(value)) {
        context.addIssue({
          path: ['startDate'],
          code: 'custom',
          message: t('common:validationError.default')
        })
      } else if (!value) {
        context.addIssue({
          path: ['startDate'],
          code: 'custom',
          message: t('common:validationError.required')
        })
      }
    }),
    endDate: date().superRefine((value, context) => {
      if (!isDate(value)) {
        context.addIssue({
          path: ['startDate'],
          code: 'custom',
          message: t('common:validationError.default')
        })
      } else if (!value) {
        context.addIssue({
          path: ['startDate'],
          code: 'custom',
          message: t('common:validationError.required')
        })
      }
    }),
    note: string().optional(),
    isFullDay:
      process.env.NEXT_PUBLIC_ENV === 'local'
        ? any().optional()
        : boolean().optional(),
    isRecurrence:
      process.env.NEXT_PUBLIC_ENV === 'local'
        ? any().optional()
        : boolean().optional(),
    timeZone: object({
      name: string().min(1)
    }).required(),
    occurrences: date().array().optional(),
    person: object({
      email: string().optional().nullable(),
      companyIds: number().array()
    }).required(),
    theaters: object({
      id: number().optional(),
      name: string().optional().nullable(),
      website: string().optional().nullable(),
      phone: string().optional().nullable(),
      email: string().optional().nullable(),
      photo: string().optional().nullable(),
      photoId: string().optional().nullable(),
      city: string().optional().nullable(),
      street: string().optional().nullable(),
      postalCode: string().optional().nullable(),
      in: string().optional().nullable()
    })
      .array()
      .min(1)
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export type PersonalEventFormValuesValidated = zodInfer<
  ReturnType<typeof usePersonalEventFormValidationSchema>['schema']
>

export type PersonalEventFormValues = Partial<PersonalEventFormValuesValidated>

export const useAllPersons = () => {
  const { data } = useGetApiStartCalendar()

  return () => {
    const persons: {
      name?: string | null
      email: string
      companyIds: number[]
    }[] = []

    data?.resources?.forEach(resource => {
      if (
        !resource.email ||
        !resource.contractPrivacy ||
        !resource.contractTerms
      ) {
        return
      }

      const arrayPerson = persons.find(p => p.email === resource.email)
      if (arrayPerson) {
        if (!arrayPerson.name && resource.name) {
          arrayPerson.name = resource.name
        }
        arrayPerson.companyIds.push(resource.companyId ?? 0)
      } else {
        persons.push({
          name: resource.name,
          email: resource.email ?? '',
          companyIds: [resource.companyId ?? 0]
        })
      }
    })

    return persons
  }
}

export const usePersonalEventSubmit = (onClose: () => void) => {
  const { t } = useTranslation(['personalEvent'])
  const {
    mutate: create,
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate
  } = usePostApiProcessCalendar()
  const {
    mutate: deleteAll,
    isLoading: isLoadingDeleteAll,
    isError: isErrorDeleteAll
  } = useDeleteApiProcessRecurring()
  const {
    mutate: deleteSingle,
    isLoading: isLoadingDeleteSingle,
    isError: isErrorDeleteSingle
  } = useDeleteApiProcessCalendarId()

  const queryClient = useQueryClient()

  const dateFns = useDateFns()

  const createNewEvent = (
    values: PersonalEventFormValuesValidated,
    oldValues?: PersonalEvent
  ) => {
    const occurrences = values.occurrences ?? []
    const occurrencesUsed: { startDate: Date; endDate: Date }[] = []

    if (occurrences.length === 0) {
      occurrencesUsed.push({
        startDate: values.startDate,
        endDate: values.endDate
      })
    } else {
      const diff = values.endDate.getTime() - values.startDate.getTime()

      occurrences.forEach(occurrence => {
        occurrencesUsed.push({
          startDate: occurrence,
          endDate: dateFns.addSeconds(occurrence, diff / 1000)
        })
      })
    }

    const recurringId = nanoid()
    const referenceId = nanoid()
    for (const occurrence of occurrencesUsed) {
      const data: CalendarProcessDTOCreate = {
        name: values.name,
        timeZone: values.timeZone.name,
        email: values.person.email,
        companyIds: values.theaters.map(theater => theater.id ?? 0),
        recurringId:
          oldValues?.recurringId ??
          (occurrences.length > 1 ? recurringId : null),
        referenceId:
          oldValues?.referenceId ??
          (values.theaters.length > 1 ? referenceId : null),
        note: values.note,
        startDate: values.isFullDay
          ? dateFns
              .startOfDay(
                zonedTimeToUtc(occurrence.startDate, values.timeZone.name)
              )
              .toISOString()
          : zonedTimeToUtc(
              occurrence.startDate,
              values.timeZone.name
            ).toISOString(),
        endDate: values.isFullDay
          ? dateFns
              .endOfDay(
                zonedTimeToUtc(occurrence.endDate, values.timeZone.name)
              )
              .toISOString()
          : zonedTimeToUtc(
              occurrence.endDate,
              values.timeZone.name
            ).toISOString()
      }

      create(
        { data },
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: any) => {
            let message

            if (
              error.response?.data?.errorCode ===
              'process/collisionWithAnotherProcess'
            ) {
              message = t(
                'personalEvent:detail.errors.collisionWithAnotherProcess'
              )
            } else {
              message = t('personalEvent:detail.errors.createUnknown')
            }

            toast.error(message)
          },
          // eslint-disable-next-line no-loop-func
          onSuccess: data => {
            if (!data) {
              toast.error(
                t('personalEvent:detail.errors.collisionWithAnotherProcess')
              )
            } else {
              queryClient.invalidateQueries({
                queryKey: getGetApiProcessCalendarFastQueryKey()
              })
              onClose()
            }
          }
        }
      )
    }
  }

  const submit = (
    values: PersonalEventFormValuesValidated,
    personalEvent?: PersonalEvent,
    forceSingleDelete?: boolean
  ) => {
    if (personalEvent) {
      const isRecurring = personalEvent.recurringId && personalEvent.referenceId

      if (isRecurring && !forceSingleDelete) {
        deleteAll(
          {
            params: {
              recurringId: personalEvent.recurringId ?? undefined,
              referenceId: personalEvent.referenceId ?? undefined
            }
          },
          {
            onSuccess: () => {
              createNewEvent(values, personalEvent)
            }
          }
        )
      } else {
        deleteSingle(
          {
            id: personalEvent.id ?? 0
          },
          {
            onSuccess: () => {
              createNewEvent(values, personalEvent)
            }
          }
        )
      }
    } else {
      createNewEvent(values)
    }
  }

  return {
    submit,
    isLoading: isLoadingCreate || isLoadingDeleteAll || isLoadingDeleteSingle,
    isSuccess: isSuccessCreate,
    isSubmitError: isErrorCreate || isErrorDeleteAll || isErrorDeleteSingle
  }
}
