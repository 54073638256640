import styled from '@emotion/styled'

export const STable = styled.table`
  border-collapse: collapse;
  position: relative;
  width: 100%;

  thead {
    border-bottom: 2px solid rgba(17, 17, 17, 0.15);
    width: 100%;
  }

  td,
  th {
    border: 1px solid rgba(17, 17, 17, 0.1);
    padding: 14px 12px;
  }

  tbody {
    width: 100%;
  }

  & thead tr,
  & tbody tr {
    width: 100%;
  }
`
