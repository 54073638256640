import { faBus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CalendarEvent,
  ProcessEventSingle
} from 'api/dotu/calendar/calendarList.utils'
import { useTranslation } from 'next-i18next'
import { getProcessStatus, useProcessColor } from 'utils/calendarStatus'
import { useDateFns } from 'utils/date'
import { useAgendaHeaderItems } from '../AgendaHeader.utils'

interface AgendaRowTitleProps {
  title: string
  event: CalendarEvent
  tourEvent?: ProcessEventSingle
  timeRangeLabel: string
}

export function AgendaRowTitle({
  title,
  event,
  tourEvent,
  timeRangeLabel
}: Readonly<AgendaRowTitleProps>) {
  const config = useAgendaHeaderItems()
  const processStatus = event.status ? getProcessStatus(event.status) : 'HIDDEN'
  const color = useProcessColor(processStatus)
  const dateFns = useDateFns()
  const { t } = useTranslation(['calendar'])

  return (
    <td
      css={{
        minWidth: config[1].width,
        width: config[1].width,
        maxWidth: config[1].width,
        boxSizing: 'border-box',
        padding: '0 !important'
      }}
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        width="100%"
        overflow="hidden"
      >
        <Box
          minWidth="4px"
          maxWidth="4px"
          flex={1}
          height="100px"
          bgcolor={color}
        />
        <Box>
          <Typography
            component="div"
            fontWeight="medium"
            sx={{
              color,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
            {event.eventType === 'tourProcess' && (
              <Chip
                label={
                  <span>
                    <FontAwesomeIcon icon={faBus} />
                    <Typography ml={1} variant="caption">
                      {event.tour?.name ??
                        t('calendar:labels.tour') + ' ' + event.tourId}
                    </Typography>
                  </span>
                }
                sx={{ ml: 1, background: '#00A49A', color: '#fff' }}
              />
            )}
          </Typography>

          {(event.eventType !== 'tourProcess' ||
            (event.eventType === 'tourProcess' &&
              event.tourProcesses?.length &&
              event.tourProcesses?.length === 1)) && (
            <Typography
              variant="body2"
              fontWeight="medium"
              sx={{
                opacity: 0.7
              }}
            >
              {timeRangeLabel}
            </Typography>
          )}

          {event.eventType === 'tourProcess' &&
            event.tourProcesses?.length &&
            event.tourProcesses?.length > 1 && (
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{
                  opacity: 0.7
                }}
              >
                {tourEvent && (
                  <>
                    {dateFns.format(tourEvent.start, 'fullTime')} –{' '}
                    {dateFns.format(tourEvent.end, 'fullTime')}
                  </>
                )}
              </Typography>
            )}
        </Box>
      </Stack>
    </td>
  )
}
