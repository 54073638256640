import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'next-i18next'
import { memo } from 'react'
import { useDateFns } from 'utils/date'

interface RecurrenceDatesProps {
  dates: Date[]
}

function RecurrenceDatesComponent({ dates }: RecurrenceDatesProps) {
  const { t } = useTranslation(['personalEvent'])
  const dateFns = useDateFns()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const itemsPerRow = isSmallDevice ? 2 : 3

  return (
    <Paper
      variant="outlined"
      sx={{ mb: 3, px: 3, py: 1, mt: { xs: 2, sm: 0 } }}
    >
      <Typography variant="caption" component="p" fontWeight="bold" mb={0.5}>
        {t('personalEvent:recurrenceDetail.allDates')}
      </Typography>
      <Stack direction="row" rowGap={1} flexWrap="wrap">
        {dates.map((date, index) => (
          <Typography
            variant="caption"
            key={date.toString()}
            width={isSmallDevice ? '50%' : '33%'}
            textAlign={
              index % itemsPerRow === 0
                ? 'left'
                : index % itemsPerRow === 1
                ? 'center'
                : 'right'
            }
          >
            {dateFns.format(date, 'fullDateTime')} (
            {dateFns.format(date, 'weekday')})
          </Typography>
        ))}
      </Stack>
    </Paper>
  )
}

export const RecurrenceDates = memo(RecurrenceDatesComponent)
