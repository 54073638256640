import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers/DatePicker'
import { useFormContext } from 'react-hook-form'
import { getFormError } from 'utils/types'
import { useDateTimeValidation } from 'utils/validations'

interface DatePickerProps extends MuiDatePickerProps<Date> {
  name: string
  isRequired?: boolean
}
export function DatePicker({
  name,
  label,
  value,
  isRequired,
  ...restProps
}: DatePickerProps) {
  const form = useFormContext()
  const isInvalid = useDateTimeValidation(value)

  const error =
    getFormError({
      form,
      name
    }) || isInvalid

  return (
    <MuiDatePicker
      {...restProps}
      value={value}
      label={label + (isRequired ? ' * ' : '')}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error && (
            <>
              <FontAwesomeIcon icon={faCircleExclamation} /> {error}
            </>
          )
        }
      }}
    />
  )
}
