import { useGetApiStartCalendar } from 'api/generated/dotu/start'

type PersonAlertType = 'email' | 'contract'

interface AlertPerson {
  id?: number
  name?: string | null
  companyName?: string | null
  alert: PersonAlertType[]
}

export const usePersonsAlertData = () => {
  const { data } = useGetApiStartCalendar()

  const personsWithoutEmail = data?.resources?.filter(
    resource => !resource.email
  )

  const personsWithoutContracts = data?.resources?.filter(
    resource => !resource.contractPrivacy || !resource.contractTerms
  )

  const personsAlertData: AlertPerson[] | undefined = personsWithoutEmail?.map(
    person => {
      const companyName = data?.companies?.find(
        company => company.id === person.companyId
      )?.name

      return {
        id: person.id,
        name: person.name,
        companyName,
        alert: ['email']
      }
    }
  )

  personsWithoutContracts?.forEach(person => {
    const existing = personsAlertData?.find(p => p.id === person.id)

    if (existing) {
      existing.alert.push('contract')
    } else {
      const companyName = data?.companies?.find(
        company => company.id === person.companyId
      )?.name

      personsAlertData?.push({
        id: person.id,
        name: person.name,
        companyName,
        alert: ['contract']
      })
    }
  })

  return personsAlertData
}
