import { faBus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { TourEvent } from 'api/dotu/calendar/calendarList.utils'
import { utcToZonedTime } from 'date-fns-tz'
import { useTranslation } from 'next-i18next'
import { useDateFns } from 'utils/date'
import { useEventTimeZone, useTimeZone } from 'utils/timeZone'

interface AgendaCardTourProps {
  event: TourEvent
  timeRangeLabel: string
}

export function AgendaCardTour({ event, timeRangeLabel }: AgendaCardTourProps) {
  const { t } = useTranslation(['calendar'])

  const getEventTimeZone = useEventTimeZone()
  const { displayedTimeZone } = useTimeZone()
  const dateFns = useDateFns()

  const getRangeLabel = () => {
    const timeZone =
      event.place?.timeZone && displayedTimeZone === undefined
        ? event.place?.timeZone
        : getEventTimeZone(event)

    const end = utcToZonedTime(event.range.end, timeZone)
    const start = utcToZonedTime(event.range.start, timeZone)

    if (end.getDay() === start.getDay()) {
      const timePeriod = `${dateFns.format(
        start,
        'fullTime'
      )} – ${dateFns.format(end, 'fullTime')}`
      return timePeriod
    }

    const startDate = dateFns.format(start, 'fullDateTime')
    const endDate = dateFns.format(end, 'fullDateTime')
    return `${startDate} – ${endDate}`
  }

  return (
    <Stack direction="column" gap={0.5} px={2} py={2.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component="div"
          variant="body2"
          fontWeight="bold"
          sx={{
            color: '#00A49A',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <FontAwesomeIcon icon={faBus} />
            {event.name ?? t('calendar:labels.tour') + ' ' + event.tourId}
          </Stack>
        </Typography>

        <Typography
          variant="body2"
          fontWeight="bold"
          sx={{
            color: '#00A49A'
          }}
        >
          {event.dateType === 'start'
            ? t('calendar:labels.tourDeparture')
            : t('calendar:labels.tourArrival')}{' '}
          {timeRangeLabel.split(' – ')[0]}
        </Typography>
      </Stack>

      <Typography
        variant="body2"
        fontWeight="medium"
        sx={{
          color: '#00A49A',
          opacity: 0.9
        }}
      >
        {getRangeLabel()}
      </Typography>
    </Stack>
  )
}
