import { faCircleX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types'
import { DateTimeValidationError } from '@mui/x-date-pickers/models'
import { DateTimePicker } from 'components/form/DateTimePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { RecurrenceFormValues } from '../../Recurrence.utils'

interface RecurrenceTabPanelIrregularlyItemProps {
  index: number
}

export function RecurrenceTabPanelIrregularlyItem({
  index
}: RecurrenceTabPanelIrregularlyItemProps) {
  const { typography } = useTheme()

  const form = useFormContext<RecurrenceFormValues>()

  const handleDelete = (index: number) => {
    const dates = form.getValues('irregular')
    dates.splice(index, 1)
    form.setValue('irregular', dates)
  }

  return (
    <Stack key={index} direction="row" alignItems="center">
      <Controller
        name={`irregular.${index}`}
        control={form.control}
        render={({ field: { onChange, value, name } }) => (
          <DateTimePicker
            value={value}
            name={name}
            label=""
            onChange={
              onChange as (
                value: Date | null,
                context: PickerChangeHandlerContext<DateTimeValidationError>
              ) => void
            }
          />
        )}
      />

      <Tooltip title="">
        <IconButton onClick={() => handleDelete(index)}>
          <FontAwesomeIcon
            icon={faCircleX}
            opacity={typography.opacity.medium}
            fontSize="1.25rem"
          />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
