import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import { isSameDay } from 'date-fns'
import { memo } from 'react'
import { DateLocalizer } from 'react-big-calendar'
import { getLocations } from '../Agenda.utils'
import { AgendaRowDay } from './AgendaRowDay'
import { AgendaRowLocation } from './AgendaRowLocation'
import { AgendaRowPersons } from './AgendaRowPersons'
import { AgendaRowProcedure } from './AgendaRowProcedure'
import { AgendaRowTitle } from './AgendaRowTitle'
import { AgendaRowTour } from './AgendaRowTour'
import { AgendaRowActions } from './actions/AgendaRowActions'

interface AgendaRowComponentProps {
  event: CalendarEvent
  localizer: DateLocalizer
  index: number
  day: Date
  timeRangeLabel: string
  size: number
}

function AgendaRowComponent({
  event,
  localizer,
  index,
  day,
  timeRangeLabel,
  size
}: AgendaRowComponentProps) {
  const tourEvent =
    event.eventType === 'tourProcess'
      ? event.tourProcesses?.find(process => isSameDay(process.start, day))
      : undefined

  return (
    <tr>
      {index === 0 && (
        <AgendaRowDay day={day} localizer={localizer} eventsCount={size} />
      )}

      {event.eventType === 'tour' ? (
        <AgendaRowTour event={event} timeRangeLabel={timeRangeLabel} />
      ) : (
        <>
          <AgendaRowTitle
            title={event.name ?? ''}
            timeRangeLabel={timeRangeLabel}
            tourEvent={tourEvent}
            event={event}
          />
          <AgendaRowPersons resources={event.resources} />
          <AgendaRowLocation
            tourEvent={tourEvent}
            locations={getLocations(event)}
            timeZone={event.timeZone}
            place={event.place}
            company={event.eventType === 'process' ? event.company : undefined}
          />
          <AgendaRowProcedure
            procedure={event.procedure}
            part={
              event.cast
                ? 'part' in event.cast
                  ? event?.cast?.part
                  : undefined
                : undefined
            }
          />
          <AgendaRowActions tourEvent={tourEvent} event={event} />
        </>
      )}
    </tr>
  )
}

export const AgendaRow = memo(AgendaRowComponent)
