import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps
} from '@mui/x-date-pickers/DateTimePicker'
import { useFormContext } from 'react-hook-form'
import { getFormError } from 'utils/types'
import { useDateTimeValidation } from 'utils/validations'

interface DateTimePickerProps extends MuiDateTimePickerProps<Date> {
  name: string
  isRequired?: boolean
}
export function DateTimePicker({
  name,
  label,
  value,
  isRequired,
  ...restProps
}: DateTimePickerProps) {
  const form = useFormContext()
  const isInvalid = useDateTimeValidation(value)

  const error =
    getFormError({
      form,
      name
    }) || isInvalid

  return (
    <MuiDateTimePicker
      {...restProps}
      value={value}
      label={label + (isRequired ? ' * ' : '')}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error && (
            <>
              <FontAwesomeIcon icon={faCircleExclamation} /> {error}
            </>
          )
        }
      }}
    />
  )
}
