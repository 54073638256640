import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import { MAX_OCCURRENCES, RecurrenceFormValues } from '../../Recurrence.utils'

interface RecurrenceTabPanelIrregularlyActionsProps {
  getNewOccurrence: (index: number) => Date | null
}

export function RecurrenceTabPanelIrregularlyActions({
  getNewOccurrence
}: RecurrenceTabPanelIrregularlyActionsProps) {
  const { t } = useTranslation(['personalEvent'])

  const { typography } = useTheme()

  const form = useFormContext<RecurrenceFormValues>()

  const dates = form.watch('irregular')

  if (dates.length === 0) {
    return null
  }

  return (
    <Stack direction="row" gap={2} mt={1}>
      {dates?.length > 0 && dates?.length < MAX_OCCURRENCES && (
        <Tooltip
          title={t('personalEvent:recurrenceDetail.irregularly.addSingle')}
        >
          <IconButton
            color="primary"
            onClick={() => {
              const date = getNewOccurrence(dates.length)
              if (date) {
                form.setValue('irregular', [...dates, date])
              }
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              opacity={typography.opacity.medium}
              fontSize="1.25rem"
            />
          </IconButton>
        </Tooltip>
      )}

      {dates?.length > 0 && (
        <Tooltip
          title={t('personalEvent:recurrenceDetail.irregularly.deleteAll')}
        >
          <IconButton
            color="error"
            onClick={() => {
              form.setValue('irregular', [])
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              opacity={typography.opacity.medium}
              fontSize="1.25rem"
            />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}
