import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const SButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  height: '32px',
  width: '32px',
  borderRadius: '100%',
  marginLeft: 'auto'
})
