import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RecurrenceFormValues } from '../../Recurrence.utils'
import { Interval } from './RecurrenceTabPanelIrregularly.utils'
import { RecurrenceTabPanelIrregularlyActions } from './RecurrenceTabPanelIrregularlyActions'
import { RecurrenceTabPanelIrregularlyEmpty } from './RecurrenceTabPanelIrregularlyEmpty'
import { RecurrenceTabPanelIrregularlyItem } from './RecurrenceTabPanelIrregularlyItem'

interface RecurrenceTabPanelIrregularlyProps {
  startDate: Date
}

export function RecurrenceTabPanelIrregularly({
  startDate
}: RecurrenceTabPanelIrregularlyProps) {
  const [interval, setInterval] = useState<Interval>('day')

  const form = useFormContext<RecurrenceFormValues>()

  const dates = form.watch('irregular')

  const getNewOccurrence = (index: number) => {
    const date = dates[index - 1] ?? startDate

    if (interval === 'day') {
      return addDays(date, index)
    } else if (interval === 'week') {
      return addWeeks(date, index)
    } else if (interval === 'month') {
      return addMonths(date, index)
    }
    return null
  }

  return (
    <Box mb={2}>
      {(!dates || dates.length === 0) && (
        <RecurrenceTabPanelIrregularlyEmpty
          getNewOccurrence={getNewOccurrence}
          interval={interval}
          setInterval={setInterval}
        />
      )}

      <Stack rowGap={2} columnGap={6} mb={2} direction="row" flexWrap="wrap">
        {dates?.map((_v, index) => (
          <RecurrenceTabPanelIrregularlyItem key={index} index={index} />
        ))}
      </Stack>

      <RecurrenceTabPanelIrregularlyActions
        getNewOccurrence={getNewOccurrence}
      />
    </Box>
  )
}
