import Box from '@mui/material/Box'
import { TimeZone, getTimeZones } from '@vvo/tzdb'
import { AutoComplete } from 'components/form/AutoComplete'
import { useTranslation } from 'next-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { getTimeZoneOffsetLabel } from 'utils/timeZone'
import { PersonalEventFormValues } from '../PersonalEventDetailModal.utils'

const timeZones = getTimeZones()

export function PersonalEventDetailModalDateTimeZone() {
  const { t } = useTranslation(['personalEvent'])

  const form = useFormContext<PersonalEventFormValues>()

  return (
    <Box>
      <Controller
        name="timeZone"
        control={form.control}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <AutoComplete
            name={name}
            value={value}
            label={t('personalEvent:detail.fields.timeZone')}
            onBlur={onBlur}
            onChange={(_e, data) => {
              onChange(data)
            }}
            isOptionEqualToValue={(option: TimeZone, value: TimeZone) =>
              option.name === value.name
            }
            getOptionLabel={(option: TimeZone) =>
              `${option.name} (${getTimeZoneOffsetLabel(option)})`
            }
            options={timeZones}
          />
        )}
      />
    </Box>
  )
}
