import {
  faBus,
  faCircleChevronDown,
  faCircleChevronUp
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  CalendarEvent,
  ProcessEventSingle
} from 'api/dotu/calendar/calendarList.utils'
import { Avatar } from 'components/avatar/Avatar'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import {
  getCastStatus,
  getProcessStatus,
  useProcessColor
} from 'utils/calendarStatus'
import { useDateFns } from 'utils/date'
import { DEFAULT_TIMEZONE, useTimeZone } from 'utils/timeZone'
import { useProcessButtonVariant } from '../row/actions/process/AgendaRowActionsProcessFilled.utils'

interface AgendaCardProps {
  event: CalendarEvent
  timeRangeLabel: string
  tourEvent?: ProcessEventSingle
  isExpanded: boolean
  setExpanded: (isExpanded: boolean) => void
}

export function AgendaCardHeader({
  event,
  timeRangeLabel,
  isExpanded,
  tourEvent,
  setExpanded
}: Readonly<AgendaCardProps>) {
  const dateFns = useDateFns()

  const timeRange = tourEvent
    ? [
        dateFns.format(tourEvent.start, 'fullTime'),
        dateFns.format(tourEvent.end, 'fullTime')
      ]
    : timeRangeLabel.split(' – ')
  const { displayedTimeZone } = useTimeZone()

  const { t } = useTranslation(['calendar'])

  const processStatus = event.status ? getProcessStatus(event.status) : 'HIDDEN'
  const color = useProcessColor(processStatus)

  const castStatus =
    event.eventType === 'process' && event.cast?.status
      ? getCastStatus(event.cast.status)
      : 'PENDING'

  const variant = useProcessButtonVariant(castStatus)

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))
  const isSmallIcon = useMediaQuery(breakpoints.down(350))
  const isMediumIcon = useMediaQuery(breakpoints.down(500))

  const getHeight = (smallDevice: boolean) => (smallDevice ? '80px' : '100px')

  const [height, setHeight] = useState(getHeight(isSmallDevice))

  const isTour = Boolean(event.tourId)

  useEffect(() => {
    const newHeight = getHeight(isSmallDevice)
    if (height !== newHeight) {
      setHeight(newHeight)
    }
  }, [height, isSmallDevice])

  return (
    <Stack direction="row" alignItems="center">
      <Box
        width="12px"
        height={height}
        sx={{ borderLeft: '6px solid', borderColor: color }}
      />

      <Stack width={isExpanded ? '60%' : '40%'} gap={0.25}>
        <Typography
          fontWeight="bold"
          variant="body2"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {event.name}
        </Typography>
        <Typography variant="body2">
          {event.place?.timeZone && displayedTimeZone === undefined
            ? event.place.timeZone
            : displayedTimeZone
            ? displayedTimeZone.name
            : event.timeZone ?? DEFAULT_TIMEZONE.name}
        </Typography>

        {isTour && (
          <Chip
            size="small"
            label={
              <div>
                <FontAwesomeIcon icon={faBus} />
                <Typography ml={1} variant="caption">
                  {t('calendar:labels.tour')} {event.tourId}
                </Typography>
              </div>
            }
            sx={{ background: '#00A49A', color: '#fff', mr: 'auto' }}
          />
        )}
      </Stack>

      {!isExpanded && (
        <Stack width="20%" justifyContent="center" alignItems="center">
          <AvatarGroup max={3}>
            {event.eventType !== 'tour' &&
              event.resources?.map(resource => (
                <Avatar
                  key={resource.id}
                  name={resource.name}
                  url={resource.photo}
                  size={32}
                  disableTooltip
                  tooltip={`
          ${resource.name} (${resource.company?.name})`}
                />
              ))}
          </AvatarGroup>
        </Stack>
      )}

      {event.eventType === 'process' && !isExpanded && (
        <Box ml="auto" mr={0.5}>
          <FontAwesomeIcon
            icon={variant.icon}
            color={variant.color}
            fontSize={
              isSmallIcon ? '1.1rem' : isMediumIcon ? '1.25rem' : '1.5rem'
            }
          />
        </Box>
      )}

      <Stack textAlign="right" ml="auto">
        <Typography variant="body2" color="primary" fontWeight="bold">
          {timeRange[0]}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {timeRange[1]}
        </Typography>
      </Stack>

      <Stack direction="row" ml="0.5rem" height={height}>
        <Divider orientation="vertical" flexItem />
        <IconButton onClick={() => setExpanded(!isExpanded)}>
          <FontAwesomeIcon
            icon={isExpanded ? faCircleChevronUp : faCircleChevronDown}
            fontSize="1.25rem"
          />
        </IconButton>
      </Stack>
    </Stack>
  )
}
