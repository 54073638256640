import Typography from '@mui/material/Typography'
import { useAgendaHeaderItems } from './AgendaHeader.utils'

export function AgendaHeader() {
  const items = useAgendaHeaderItems()

  return (
    <thead css={{ textAlign: 'center' }}>
      <tr>
        {items.map(item => (
          <td
            key={item.label}
            css={{
              minWidth: item.width,
              width: item.width,
              maxWidth: item.width
            }}
          >
            <Typography fontWeight="bold">{item.label}</Typography>
          </td>
        ))}
      </tr>
    </thead>
  )
}
