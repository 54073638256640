import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import cs from 'date-fns/locale/cs'
import enUS from 'date-fns/locale/en-US'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FunctionComponent, useMemo, useState } from 'react'
import {
  Calendar as BigCalendar,
  ViewStatic,
  dateFnsLocalizer
} from 'react-big-calendar'
import { useEvents } from './EventsContext'
import { CalendarActions } from './actions/CalendarActions'
import { CalendarLegends } from './legends/CalendarLegends'
import { Toolbar } from './toolbar/Toolbar'
import { Agenda } from './views/agenda/Agenda'

const locales = {
  en: enUS,
  cs: cs
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

export function Calendar() {
  const { t } = useTranslation(['calendar'])

  const { locale, query } = useRouter()
  const activeView = query.view as string

  const { events, range, setCurrentMonth, setNextMonth, setPreviousMonth } =
    useEvents()

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('lg'))
  const [isLegendDisplayed, setLegendDisplayed] = useState(true)

  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        agenda: Agenda as unknown as FunctionComponent<any> & ViewStatic
      }
    }),
    []
  )

  const calendarContent = (
    <BigCalendar
      components={{
        toolbar: Toolbar
      }}
      messages={{
        date: t('calendar:labels.date'),
        time: t('calendar:labels.time'),
        event: t('calendar:labels.event'),
        allDay: t('calendar:labels.allDay'),
        week: t('calendar:labels.week'),
        work_week: t('calendar:labels.workWeek'),
        day: t('calendar:labels.day'),
        month: t('calendar:labels.month'),
        previous: t('calendar:labels.previous'),
        next: t('calendar:labels.next'),
        yesterday: t('calendar:labels.yesterday'),
        tomorrow: t('calendar:labels.tomorrow'),
        today: t('calendar:labels.today'),
        agenda: t('calendar:labels.agenda'),
        noEventsInRange: t('calendar:labels.noEvents'),
        showMore: total => `+${total} ${t('calendar:labels.more')}`
      }}
      localizer={localizer}
      date={range.start && new Date(range.start)}
      culture={locale}
      onNavigate={(_date, _view, action) => {
        if (action === 'TODAY') {
          setCurrentMonth()
        }
        if (action === 'NEXT') {
          setNextMonth()
        }
        if (action === 'PREV') {
          setPreviousMonth()
        }
      }}
      events={events}
      views={views}
      view={activeView === 'month' ? 'month' : 'agenda'}
      onView={() => undefined}
      style={{ minHeight: '80vh' }}
    />
  )

  return (
    <Stack direction="column">
      <Typography
        variant="h2"
        marginBottom={{ xs: 2.5, sm: 5 }}
        textAlign="center"
      >
        {t('calendar:title')}
      </Typography>

      {isLegendDisplayed ? (
        <Box marginBottom={4}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
          >
            <CalendarLegends setLegendDisplayed={setLegendDisplayed} />
            <CalendarActions />
          </Stack>
        </Box>
      ) : isSmallDevice ? (
        <CalendarActions />
      ) : null}

      {isSmallDevice ? (
        calendarContent
      ) : (
        <Paper elevation={24} sx={{ flex: 1, overflow: 'hidden' }}>
          {calendarContent}
        </Paper>
      )}
    </Stack>
  )
}
