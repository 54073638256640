import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CalendarEvent } from 'api/dotu/calendar/calendarList.utils'
import { isSameDay } from 'date-fns'
import { useState } from 'react'
import { DateLocalizer } from 'react-big-calendar'
import { AgendaCardExpanded } from './AgendaCardExpanded'
import { AgendaCardHeader } from './AgendaCardHeader'
import { AgendaCardTour } from './AgendaCardTour'

interface AgendaCardProps {
  event: CalendarEvent
  localizer: DateLocalizer
  day: Date
  index: number
  timeRangeLabel: string
}

export function AgendaCard({
  index,
  localizer,
  day,
  event,
  timeRangeLabel
}: AgendaCardProps) {
  const [isExpanded, setExpanded] = useState(false)

  const tourEvent =
    event.eventType === 'tourProcess'
      ? event.tourProcesses?.find(process => isSameDay(process.start, day))
      : undefined

  return (
    <>
      {index === 0 && (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          width="100%"
          mb={{ xs: 1, sm: 2 }}
          mt={{ xs: 1, sm: 2 }}
        >
          <Typography variant="h5" fontWeight="bold">
            {localizer.format(day, 'dd.MM.')}
          </Typography>

          <Typography
            variant="body1"
            fontWeight="medium"
            textTransform="capitalize"
            sx={{
              opacity: 0.87
            }}
          >
            {localizer.format(day, 'EEEE')}
          </Typography>
        </Stack>
      )}

      <Card sx={{ width: '100%' }}>
        {event.eventType === 'tour' ? (
          <AgendaCardTour event={event} timeRangeLabel={timeRangeLabel} />
        ) : (
          <Stack direction="column">
            <AgendaCardHeader
              event={event}
              timeRangeLabel={timeRangeLabel}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
              tourEvent={tourEvent}
            />

            {isExpanded && (
              <>
                <Divider />
                <AgendaCardExpanded
                  timeRangeLabel={timeRangeLabel}
                  event={event}
                  tourEvent={tourEvent}
                />
              </>
            )}
          </Stack>
        )}
      </Card>
    </>
  )
}
