import styled from '@emotion/styled'
import { motion } from 'framer-motion'

export const SWrapper = styled(motion.nav)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`
