import {
  faBuildingColumns,
  faBus,
  faMasksTheater
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  CalendarEvent,
  ProcessEventSingle
} from 'api/dotu/calendar/calendarList.utils'
import { Avatar } from 'components/avatar/Avatar'
import { useTranslation } from 'next-i18next'
import { getLocations } from '../Agenda.utils'
import { AgendaRowActions } from '../row/actions/AgendaRowActions'

interface AgendaCardExpandedProps {
  event: CalendarEvent
  timeRangeLabel: string
  tourEvent?: ProcessEventSingle
}

export function AgendaCardExpanded({
  event,
  timeRangeLabel,
  tourEvent
}: AgendaCardExpandedProps) {
  const part = event.cast
    ? 'part' in event.cast
      ? event?.cast?.part
      : undefined
    : undefined

  const { t } = useTranslation(['calendar'])

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))
  const isMediumDevice = useMediaQuery(breakpoints.down('lg'))

  const isSmallerDevice = !isSmallDevice && isMediumDevice

  const isTour = Boolean(event.tourId)

  return (
    <div>
      <Stack
        direction="row"
        gap={0.5}
        flexWrap="wrap"
        px={isSmallerDevice ? 2 : 1}
        py={isSmallerDevice ? 2.5 : 1.5}
      >
        {event.eventType !== 'tour' &&
          event.resources?.map(resource => (
            <Chip
              key={resource.id}
              avatar={
                <Avatar
                  name={resource.name}
                  url={resource.photo}
                  size={28}
                  disableTooltip
                  tooltip={`
    ${resource.name} (${resource.company?.name})`}
                />
              }
              label={
                <Typography variant="body2">{`${resource.name} (${resource.company?.name})`}</Typography>
              }
            />
          ))}
      </Stack>

      {(event.eventType === 'process' || event.eventType === 'tour') && (
        <>
          <Divider />

          <Stack
            px={isSmallerDevice ? 2 : 1}
            py={isSmallerDevice ? 2.5 : 1.5}
            direction="row"
            gap={isSmallerDevice ? 5 : 3}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Stack direction="row" gap={1} alignItems="center">
              <FontAwesomeIcon
                icon={isTour ? faBus : faBuildingColumns}
                fontSize="1rem"
                opacity="0.6"
              />

              <Stack>
                <Typography
                  fontWeight="medium"
                  variant="body2"
                  sx={{
                    opacity: 0.87
                  }}
                >
                  {tourEvent?.place?.name ?? event.place?.name}
                </Typography>

                {((isTour && getLocations(event).length === 1) ||
                  event.company?.name) && (
                  <Typography
                    variant="body2"
                    sx={{
                      opacity: event.place?.name ? 0.7 : 0.87,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {event.company?.name}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <FontAwesomeIcon
                icon={faMasksTheater}
                fontSize="1rem"
                opacity="0.6"
              />

              <Stack>
                {event.procedure?.name && (
                  <Typography
                    fontWeight="medium"
                    variant="body2"
                    sx={{
                      opacity: 0.87,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {event.procedure.name}
                  </Typography>
                )}

                {part?.name && (
                  <Typography
                    variant="body2"
                    sx={{
                      opacity: event.procedure?.name ? 0.7 : 0.87,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {part?.name}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      <Divider />

      {event.eventType === 'tourProcess' &&
        event.tourProcesses?.length &&
        event.tourProcesses.length > 1 && (
          <>
            <Stack
              px={isSmallerDevice ? 2 : 1}
              py={isSmallerDevice ? 2.5 : 1.5}
              alignItems="center"
            >
              <Typography variant="body2" fontWeight="bold">
                {t('calendar:labels.tour')}
              </Typography>
              <Typography variant="body2">{timeRangeLabel}</Typography>
            </Stack>
            <Divider />
          </>
        )}

      <Box px={1} py={1.5}>
        <AgendaRowActions event={event} />
      </Box>
    </div>
  )
}
