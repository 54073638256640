import { useGetApiStartCalendar } from 'api/generated/dotu/start'
import { useTranslation } from 'next-i18next'

export const useCastStatusLabel = () => {
  const { t } = useTranslation(['app'])

  return (status: string) => {
    if (status === 'PENDING') return t('app:actions.pending')
    if (status === 'ACCEPTED') return t('app:actions.accepted')
    if (status === 'DECLINED') return t('app:actions.declined')
    if (status === 'CONFIRMED') return t('app:actions.confirmed')
    return ''
  }
}

export const useProcessStatusLabel = () => {
  const { t } = useTranslation(['calendar'])

  return (status: string) => {
    if (status === 'PENDING') return t('calendar:legend.pending.label')
    if (status === 'CONFIRMED') return t('calendar:legend.confirmed.label')
    if (status === 'DECLINED') return t('calendar:legend.rejected.label')
    return ''
  }
}

export const useResourceLabel = () => {
  const { data } = useGetApiStartCalendar()

  return (id: number) => {
    const resource = data?.resources?.find(
      resource => resource.id === parseInt(id.toString())
    )

    if (resource) {
      const company = data?.companies?.find(
        company => company.id === resource.companyId
      )

      return `${resource.name} (${company?.name})`
    }
    return ''
  }
}
