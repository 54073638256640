import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { utcToZonedTime } from 'date-fns-tz'
import startOfMonth from 'date-fns/startOfMonth'
import { useState } from 'react'
import { useEvents } from 'sections/calendar/EventsContext'
import { useDateFns } from 'utils/date'
import { useNotifications } from 'utils/notifications'
import { DEFAULT_TIMEZONE, useTimeZone } from 'utils/timeZone'
import { MonthSelectorDialog } from './MonthSelectorDialog'

interface MonthSelectorProps {
  activeDate: Date
}

export function MonthSelector({ activeDate }: MonthSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { breakpoints } = useTheme()
  const isSmallDevice = useMediaQuery(breakpoints.down('sm'))

  const dateFns = useDateFns()

  const { setNextMonth, setPreviousMonth, range } = useEvents()

  const notifications = useNotifications()

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { displayedTimeZone } = useTimeZone()

  const activeDateStart = startOfMonth(activeDate)
  const monthString =
    new Date(activeDateStart).toString() !== 'Invalid Date' &&
    dateFns.format(activeDateStart, 'month')

  const timeZoned = displayedTimeZone
    ? utcToZonedTime(activeDate, displayedTimeZone.name).getTime()
    : utcToZonedTime(activeDate, DEFAULT_TIMEZONE.name).getTime()
  const notificationsCount = notifications.processes.filter(process => {
    const timeZone =
      process.place?.timeZone && displayedTimeZone === undefined
        ? process.place?.timeZone
        : DEFAULT_TIMEZONE.name

    return (
      startOfMonth(utcToZonedTime(process.start, timeZone)).setHours(
        0,
        0,
        0,
        0
      ) === startOfMonth(timeZoned).setHours(0, 0, 0, 0)
    )
  }).length

  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton
          onClick={setPreviousMonth}
          sx={{ border: 'none !important' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} fontSize="1rem" />
        </IconButton>

        <Stack
          justifyContent="center"
          alignItems="center"
          width={isSmallDevice ? '100px' : '150px'}
        >
          <Badge badgeContent={notificationsCount} color="error" sx={{ mx: 2 }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              display="inline-flex"
              sx={{ cursor: 'pointer' }}
              onClick={handleOpen}
            >
              <Typography
                variant={isSmallDevice ? 'h6' : 'h5'}
                color="text.primary"
              >
                {monthString && range.start ? (
                  monthString.charAt(0).toUpperCase() + monthString.slice(1)
                ) : (
                  <Skeleton variant="text" width="125px" height="32px" />
                )}
              </Typography>
              <Typography
                variant="body1"
                mt={-0.75}
                fontWeight="bold"
                color="text.secondary"
              >
                {monthString && range.start ? (
                  dateFns.format(activeDateStart, 'year')
                ) : (
                  <Skeleton variant="text" width="125px" height="24px" />
                )}
              </Typography>
            </Stack>
          </Badge>
        </Stack>

        <IconButton onClick={setNextMonth} sx={{ border: 'none !important' }}>
          <FontAwesomeIcon icon={faChevronRight} fontSize="1rem" />
        </IconButton>
      </Stack>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MonthSelectorDialog
          activeDate={activeDateStart}
          onClose={handleClose}
        />
      </Menu>
    </>
  )
}
