import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DateLocalizer } from 'react-big-calendar'
import { useAgendaHeaderItems } from '../AgendaHeader.utils'

interface AgendaRowDayProps {
  day: Date
  localizer: DateLocalizer
  eventsCount: number
}

export function AgendaRowDay({
  day,
  localizer,
  eventsCount
}: AgendaRowDayProps) {
  const config = useAgendaHeaderItems()

  return (
    <th
      rowSpan={eventsCount}
      css={{
        minWidth: config[0].width,
        width: config[0].width,
        maxWidth: config[0].width,
        boxSizing: 'border-box'
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        overflow="hidden"
      >
        <Typography variant="h5" fontWeight="bold">
          {localizer.format(day, 'dd.MM.')}
        </Typography>
        <Typography
          variant="body1"
          fontWeight="medium"
          textTransform="capitalize"
          sx={{
            opacity: 0.87
          }}
        >
          {localizer.format(day, 'EEEE')}
        </Typography>
      </Stack>
    </th>
  )
}
